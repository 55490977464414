@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
/*!
 * Font Awesome Pro 5.5.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em; }

.fa-xs {
  font-size: .75em; }

.fa-sm {
  font-size: .875em; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit; }

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em; }

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em; }

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
          animation: fa-spin 2s infinite linear; }

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
          animation: fa-spin 1s infinite steps(8); }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1); }

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
          transform: scale(1, -1); }

.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1); }

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  -webkit-filter: none;
          filter: none; }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%; }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #fff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\F26E"; }

.fa-abacus:before {
  content: "\F640"; }

.fa-accessible-icon:before {
  content: "\F368"; }

.fa-accusoft:before {
  content: "\F369"; }

.fa-acorn:before {
  content: "\F6AE"; }

.fa-acquisitions-incorporated:before {
  content: "\F6AF"; }

.fa-ad:before {
  content: "\F641"; }

.fa-address-book:before {
  content: "\F2B9"; }

.fa-address-card:before {
  content: "\F2BB"; }

.fa-adjust:before {
  content: "\F042"; }

.fa-adn:before {
  content: "\F170"; }

.fa-adversal:before {
  content: "\F36A"; }

.fa-affiliatetheme:before {
  content: "\F36B"; }

.fa-air-freshener:before {
  content: "\F5D0"; }

.fa-alarm-clock:before {
  content: "\F34E"; }

.fa-algolia:before {
  content: "\F36C"; }

.fa-alicorn:before {
  content: "\F6B0"; }

.fa-align-center:before {
  content: "\F037"; }

.fa-align-justify:before {
  content: "\F039"; }

.fa-align-left:before {
  content: "\F036"; }

.fa-align-right:before {
  content: "\F038"; }

.fa-alipay:before {
  content: "\F642"; }

.fa-allergies:before {
  content: "\F461"; }

.fa-amazon:before {
  content: "\F270"; }

.fa-amazon-pay:before {
  content: "\F42C"; }

.fa-ambulance:before {
  content: "\F0F9"; }

.fa-american-sign-language-interpreting:before {
  content: "\F2A3"; }

.fa-amilia:before {
  content: "\F36D"; }

.fa-analytics:before {
  content: "\F643"; }

.fa-anchor:before {
  content: "\F13D"; }

.fa-android:before {
  content: "\F17B"; }

.fa-angellist:before {
  content: "\F209"; }

.fa-angle-double-down:before {
  content: "\F103"; }

.fa-angle-double-left:before {
  content: "\F100"; }

.fa-angle-double-right:before {
  content: "\F101"; }

.fa-angle-double-up:before {
  content: "\F102"; }

.fa-angle-down:before {
  content: "\F107"; }

.fa-angle-left:before {
  content: "\F104"; }

.fa-angle-right:before {
  content: "\F105"; }

.fa-angle-up:before {
  content: "\F106"; }

.fa-angry:before {
  content: "\F556"; }

.fa-angrycreative:before {
  content: "\F36E"; }

.fa-angular:before {
  content: "\F420"; }

.fa-ankh:before {
  content: "\F644"; }

.fa-app-store:before {
  content: "\F36F"; }

.fa-app-store-ios:before {
  content: "\F370"; }

.fa-apper:before {
  content: "\F371"; }

.fa-apple:before {
  content: "\F179"; }

.fa-apple-alt:before {
  content: "\F5D1"; }

.fa-apple-crate:before {
  content: "\F6B1"; }

.fa-apple-pay:before {
  content: "\F415"; }

.fa-archive:before {
  content: "\F187"; }

.fa-archway:before {
  content: "\F557"; }

.fa-arrow-alt-circle-down:before {
  content: "\F358"; }

.fa-arrow-alt-circle-left:before {
  content: "\F359"; }

.fa-arrow-alt-circle-right:before {
  content: "\F35A"; }

.fa-arrow-alt-circle-up:before {
  content: "\F35B"; }

.fa-arrow-alt-down:before {
  content: "\F354"; }

.fa-arrow-alt-from-bottom:before {
  content: "\F346"; }

.fa-arrow-alt-from-left:before {
  content: "\F347"; }

.fa-arrow-alt-from-right:before {
  content: "\F348"; }

.fa-arrow-alt-from-top:before {
  content: "\F349"; }

.fa-arrow-alt-left:before {
  content: "\F355"; }

.fa-arrow-alt-right:before {
  content: "\F356"; }

.fa-arrow-alt-square-down:before {
  content: "\F350"; }

.fa-arrow-alt-square-left:before {
  content: "\F351"; }

.fa-arrow-alt-square-right:before {
  content: "\F352"; }

.fa-arrow-alt-square-up:before {
  content: "\F353"; }

.fa-arrow-alt-to-bottom:before {
  content: "\F34A"; }

.fa-arrow-alt-to-left:before {
  content: "\F34B"; }

.fa-arrow-alt-to-right:before {
  content: "\F34C"; }

.fa-arrow-alt-to-top:before {
  content: "\F34D"; }

.fa-arrow-alt-up:before {
  content: "\F357"; }

.fa-arrow-circle-down:before {
  content: "\F0AB"; }

.fa-arrow-circle-left:before {
  content: "\F0A8"; }

.fa-arrow-circle-right:before {
  content: "\F0A9"; }

.fa-arrow-circle-up:before {
  content: "\F0AA"; }

.fa-arrow-down:before {
  content: "\F063"; }

.fa-arrow-from-bottom:before {
  content: "\F342"; }

.fa-arrow-from-left:before {
  content: "\F343"; }

.fa-arrow-from-right:before {
  content: "\F344"; }

.fa-arrow-from-top:before {
  content: "\F345"; }

.fa-arrow-left:before {
  content: "\F060"; }

.fa-arrow-right:before {
  content: "\F061"; }

.fa-arrow-square-down:before {
  content: "\F339"; }

.fa-arrow-square-left:before {
  content: "\F33A"; }

.fa-arrow-square-right:before {
  content: "\F33B"; }

.fa-arrow-square-up:before {
  content: "\F33C"; }

.fa-arrow-to-bottom:before {
  content: "\F33D"; }

.fa-arrow-to-left:before {
  content: "\F33E"; }

.fa-arrow-to-right:before {
  content: "\F340"; }

.fa-arrow-to-top:before {
  content: "\F341"; }

.fa-arrow-up:before {
  content: "\F062"; }

.fa-arrows:before {
  content: "\F047"; }

.fa-arrows-alt:before {
  content: "\F0B2"; }

.fa-arrows-alt-h:before {
  content: "\F337"; }

.fa-arrows-alt-v:before {
  content: "\F338"; }

.fa-arrows-h:before {
  content: "\F07E"; }

.fa-arrows-v:before {
  content: "\F07D"; }

.fa-assistive-listening-systems:before {
  content: "\F2A2"; }

.fa-asterisk:before {
  content: "\F069"; }

.fa-asymmetrik:before {
  content: "\F372"; }

.fa-at:before {
  content: "\F1FA"; }

.fa-atlas:before {
  content: "\F558"; }

.fa-atom:before {
  content: "\F5D2"; }

.fa-atom-alt:before {
  content: "\F5D3"; }

.fa-audible:before {
  content: "\F373"; }

.fa-audio-description:before {
  content: "\F29E"; }

.fa-autoprefixer:before {
  content: "\F41C"; }

.fa-avianex:before {
  content: "\F374"; }

.fa-aviato:before {
  content: "\F421"; }

.fa-award:before {
  content: "\F559"; }

.fa-aws:before {
  content: "\F375"; }

.fa-axe:before {
  content: "\F6B2"; }

.fa-axe-battle:before {
  content: "\F6B3"; }

.fa-backpack:before {
  content: "\F5D4"; }

.fa-backspace:before {
  content: "\F55A"; }

.fa-backward:before {
  content: "\F04A"; }

.fa-badge:before {
  content: "\F335"; }

.fa-badge-check:before {
  content: "\F336"; }

.fa-badge-dollar:before {
  content: "\F645"; }

.fa-badge-percent:before {
  content: "\F646"; }

.fa-badger-honey:before {
  content: "\F6B4"; }

.fa-balance-scale:before {
  content: "\F24E"; }

.fa-balance-scale-left:before {
  content: "\F515"; }

.fa-balance-scale-right:before {
  content: "\F516"; }

.fa-ballot:before {
  content: "\F732"; }

.fa-ballot-check:before {
  content: "\F733"; }

.fa-ban:before {
  content: "\F05E"; }

.fa-band-aid:before {
  content: "\F462"; }

.fa-bandcamp:before {
  content: "\F2D5"; }

.fa-barcode:before {
  content: "\F02A"; }

.fa-barcode-alt:before {
  content: "\F463"; }

.fa-barcode-read:before {
  content: "\F464"; }

.fa-barcode-scan:before {
  content: "\F465"; }

.fa-bars:before {
  content: "\F0C9"; }

.fa-baseball:before {
  content: "\F432"; }

.fa-baseball-ball:before {
  content: "\F433"; }

.fa-basketball-ball:before {
  content: "\F434"; }

.fa-basketball-hoop:before {
  content: "\F435"; }

.fa-bat:before {
  content: "\F6B5"; }

.fa-bath:before {
  content: "\F2CD"; }

.fa-battery-bolt:before {
  content: "\F376"; }

.fa-battery-empty:before {
  content: "\F244"; }

.fa-battery-full:before {
  content: "\F240"; }

.fa-battery-half:before {
  content: "\F242"; }

.fa-battery-quarter:before {
  content: "\F243"; }

.fa-battery-slash:before {
  content: "\F377"; }

.fa-battery-three-quarters:before {
  content: "\F241"; }

.fa-bed:before {
  content: "\F236"; }

.fa-beer:before {
  content: "\F0FC"; }

.fa-behance:before {
  content: "\F1B4"; }

.fa-behance-square:before {
  content: "\F1B5"; }

.fa-bell:before {
  content: "\F0F3"; }

.fa-bell-school:before {
  content: "\F5D5"; }

.fa-bell-school-slash:before {
  content: "\F5D6"; }

.fa-bell-slash:before {
  content: "\F1F6"; }

.fa-bezier-curve:before {
  content: "\F55B"; }

.fa-bible:before {
  content: "\F647"; }

.fa-bicycle:before {
  content: "\F206"; }

.fa-bimobject:before {
  content: "\F378"; }

.fa-binoculars:before {
  content: "\F1E5"; }

.fa-birthday-cake:before {
  content: "\F1FD"; }

.fa-bitbucket:before {
  content: "\F171"; }

.fa-bitcoin:before {
  content: "\F379"; }

.fa-bity:before {
  content: "\F37A"; }

.fa-black-tie:before {
  content: "\F27E"; }

.fa-blackberry:before {
  content: "\F37B"; }

.fa-blanket:before {
  content: "\F498"; }

.fa-blender:before {
  content: "\F517"; }

.fa-blender-phone:before {
  content: "\F6B6"; }

.fa-blind:before {
  content: "\F29D"; }

.fa-blogger:before {
  content: "\F37C"; }

.fa-blogger-b:before {
  content: "\F37D"; }

.fa-bluetooth:before {
  content: "\F293"; }

.fa-bluetooth-b:before {
  content: "\F294"; }

.fa-bold:before {
  content: "\F032"; }

.fa-bolt:before {
  content: "\F0E7"; }

.fa-bomb:before {
  content: "\F1E2"; }

.fa-bone:before {
  content: "\F5D7"; }

.fa-bone-break:before {
  content: "\F5D8"; }

.fa-bong:before {
  content: "\F55C"; }

.fa-book:before {
  content: "\F02D"; }

.fa-book-alt:before {
  content: "\F5D9"; }

.fa-book-dead:before {
  content: "\F6B7"; }

.fa-book-heart:before {
  content: "\F499"; }

.fa-book-open:before {
  content: "\F518"; }

.fa-book-reader:before {
  content: "\F5DA"; }

.fa-book-spells:before {
  content: "\F6B8"; }

.fa-bookmark:before {
  content: "\F02E"; }

.fa-books:before {
  content: "\F5DB"; }

.fa-booth-curtain:before {
  content: "\F734"; }

.fa-bow-arrow:before {
  content: "\F6B9"; }

.fa-bowling-ball:before {
  content: "\F436"; }

.fa-bowling-pins:before {
  content: "\F437"; }

.fa-box:before {
  content: "\F466"; }

.fa-box-alt:before {
  content: "\F49A"; }

.fa-box-ballot:before {
  content: "\F735"; }

.fa-box-check:before {
  content: "\F467"; }

.fa-box-fragile:before {
  content: "\F49B"; }

.fa-box-full:before {
  content: "\F49C"; }

.fa-box-heart:before {
  content: "\F49D"; }

.fa-box-open:before {
  content: "\F49E"; }

.fa-box-up:before {
  content: "\F49F"; }

.fa-box-usd:before {
  content: "\F4A0"; }

.fa-boxes:before {
  content: "\F468"; }

.fa-boxes-alt:before {
  content: "\F4A1"; }

.fa-boxing-glove:before {
  content: "\F438"; }

.fa-braille:before {
  content: "\F2A1"; }

.fa-brain:before {
  content: "\F5DC"; }

.fa-briefcase:before {
  content: "\F0B1"; }

.fa-briefcase-medical:before {
  content: "\F469"; }

.fa-broadcast-tower:before {
  content: "\F519"; }

.fa-broom:before {
  content: "\F51A"; }

.fa-browser:before {
  content: "\F37E"; }

.fa-brush:before {
  content: "\F55D"; }

.fa-btc:before {
  content: "\F15A"; }

.fa-bug:before {
  content: "\F188"; }

.fa-building:before {
  content: "\F1AD"; }

.fa-bullhorn:before {
  content: "\F0A1"; }

.fa-bullseye:before {
  content: "\F140"; }

.fa-bullseye-arrow:before {
  content: "\F648"; }

.fa-bullseye-pointer:before {
  content: "\F649"; }

.fa-burn:before {
  content: "\F46A"; }

.fa-buromobelexperte:before {
  content: "\F37F"; }

.fa-bus:before {
  content: "\F207"; }

.fa-bus-alt:before {
  content: "\F55E"; }

.fa-bus-school:before {
  content: "\F5DD"; }

.fa-business-time:before {
  content: "\F64A"; }

.fa-buysellads:before {
  content: "\F20D"; }

.fa-cabinet-filing:before {
  content: "\F64B"; }

.fa-calculator:before {
  content: "\F1EC"; }

.fa-calculator-alt:before {
  content: "\F64C"; }

.fa-calendar:before {
  content: "\F133"; }

.fa-calendar-alt:before {
  content: "\F073"; }

.fa-calendar-check:before {
  content: "\F274"; }

.fa-calendar-edit:before {
  content: "\F333"; }

.fa-calendar-exclamation:before {
  content: "\F334"; }

.fa-calendar-minus:before {
  content: "\F272"; }

.fa-calendar-plus:before {
  content: "\F271"; }

.fa-calendar-star:before {
  content: "\F736"; }

.fa-calendar-times:before {
  content: "\F273"; }

.fa-camera:before {
  content: "\F030"; }

.fa-camera-alt:before {
  content: "\F332"; }

.fa-camera-retro:before {
  content: "\F083"; }

.fa-campfire:before {
  content: "\F6BA"; }

.fa-campground:before {
  content: "\F6BB"; }

.fa-candle-holder:before {
  content: "\F6BC"; }

.fa-candy-corn:before {
  content: "\F6BD"; }

.fa-cannabis:before {
  content: "\F55F"; }

.fa-capsules:before {
  content: "\F46B"; }

.fa-car:before {
  content: "\F1B9"; }

.fa-car-alt:before {
  content: "\F5DE"; }

.fa-car-battery:before {
  content: "\F5DF"; }

.fa-car-bump:before {
  content: "\F5E0"; }

.fa-car-crash:before {
  content: "\F5E1"; }

.fa-car-garage:before {
  content: "\F5E2"; }

.fa-car-mechanic:before {
  content: "\F5E3"; }

.fa-car-side:before {
  content: "\F5E4"; }

.fa-car-tilt:before {
  content: "\F5E5"; }

.fa-car-wash:before {
  content: "\F5E6"; }

.fa-caret-circle-down:before {
  content: "\F32D"; }

.fa-caret-circle-left:before {
  content: "\F32E"; }

.fa-caret-circle-right:before {
  content: "\F330"; }

.fa-caret-circle-up:before {
  content: "\F331"; }

.fa-caret-down:before {
  content: "\F0D7"; }

.fa-caret-left:before {
  content: "\F0D9"; }

.fa-caret-right:before {
  content: "\F0DA"; }

.fa-caret-square-down:before {
  content: "\F150"; }

.fa-caret-square-left:before {
  content: "\F191"; }

.fa-caret-square-right:before {
  content: "\F152"; }

.fa-caret-square-up:before {
  content: "\F151"; }

.fa-caret-up:before {
  content: "\F0D8"; }

.fa-cart-arrow-down:before {
  content: "\F218"; }

.fa-cart-plus:before {
  content: "\F217"; }

.fa-cat:before {
  content: "\F6BE"; }

.fa-cauldron:before {
  content: "\F6BF"; }

.fa-cc-amazon-pay:before {
  content: "\F42D"; }

.fa-cc-amex:before {
  content: "\F1F3"; }

.fa-cc-apple-pay:before {
  content: "\F416"; }

.fa-cc-diners-club:before {
  content: "\F24C"; }

.fa-cc-discover:before {
  content: "\F1F2"; }

.fa-cc-jcb:before {
  content: "\F24B"; }

.fa-cc-mastercard:before {
  content: "\F1F1"; }

.fa-cc-paypal:before {
  content: "\F1F4"; }

.fa-cc-stripe:before {
  content: "\F1F5"; }

.fa-cc-visa:before {
  content: "\F1F0"; }

.fa-centercode:before {
  content: "\F380"; }

.fa-certificate:before {
  content: "\F0A3"; }

.fa-chair:before {
  content: "\F6C0"; }

.fa-chair-office:before {
  content: "\F6C1"; }

.fa-chalkboard:before {
  content: "\F51B"; }

.fa-chalkboard-teacher:before {
  content: "\F51C"; }

.fa-charging-station:before {
  content: "\F5E7"; }

.fa-chart-area:before {
  content: "\F1FE"; }

.fa-chart-bar:before {
  content: "\F080"; }

.fa-chart-line:before {
  content: "\F201"; }

.fa-chart-line-down:before {
  content: "\F64D"; }

.fa-chart-pie:before {
  content: "\F200"; }

.fa-chart-pie-alt:before {
  content: "\F64E"; }

.fa-check:before {
  content: "\F00C"; }

.fa-check-circle:before {
  content: "\F058"; }

.fa-check-double:before {
  content: "\F560"; }

.fa-check-square:before {
  content: "\F14A"; }

.fa-chess:before {
  content: "\F439"; }

.fa-chess-bishop:before {
  content: "\F43A"; }

.fa-chess-bishop-alt:before {
  content: "\F43B"; }

.fa-chess-board:before {
  content: "\F43C"; }

.fa-chess-clock:before {
  content: "\F43D"; }

.fa-chess-clock-alt:before {
  content: "\F43E"; }

.fa-chess-king:before {
  content: "\F43F"; }

.fa-chess-king-alt:before {
  content: "\F440"; }

.fa-chess-knight:before {
  content: "\F441"; }

.fa-chess-knight-alt:before {
  content: "\F442"; }

.fa-chess-pawn:before {
  content: "\F443"; }

.fa-chess-pawn-alt:before {
  content: "\F444"; }

.fa-chess-queen:before {
  content: "\F445"; }

.fa-chess-queen-alt:before {
  content: "\F446"; }

.fa-chess-rook:before {
  content: "\F447"; }

.fa-chess-rook-alt:before {
  content: "\F448"; }

.fa-chevron-circle-down:before {
  content: "\F13A"; }

.fa-chevron-circle-left:before {
  content: "\F137"; }

.fa-chevron-circle-right:before {
  content: "\F138"; }

.fa-chevron-circle-up:before {
  content: "\F139"; }

.fa-chevron-double-down:before {
  content: "\F322"; }

.fa-chevron-double-left:before {
  content: "\F323"; }

.fa-chevron-double-right:before {
  content: "\F324"; }

.fa-chevron-double-up:before {
  content: "\F325"; }

.fa-chevron-down:before {
  content: "\F078"; }

.fa-chevron-left:before {
  content: "\F053"; }

.fa-chevron-right:before {
  content: "\F054"; }

.fa-chevron-square-down:before {
  content: "\F329"; }

.fa-chevron-square-left:before {
  content: "\F32A"; }

.fa-chevron-square-right:before {
  content: "\F32B"; }

.fa-chevron-square-up:before {
  content: "\F32C"; }

.fa-chevron-up:before {
  content: "\F077"; }

.fa-child:before {
  content: "\F1AE"; }

.fa-chrome:before {
  content: "\F268"; }

.fa-church:before {
  content: "\F51D"; }

.fa-circle:before {
  content: "\F111"; }

.fa-circle-notch:before {
  content: "\F1CE"; }

.fa-city:before {
  content: "\F64F"; }

.fa-claw-marks:before {
  content: "\F6C2"; }

.fa-clipboard:before {
  content: "\F328"; }

.fa-clipboard-check:before {
  content: "\F46C"; }

.fa-clipboard-list:before {
  content: "\F46D"; }

.fa-clipboard-list-check:before {
  content: "\F737"; }

.fa-clipboard-prescription:before {
  content: "\F5E8"; }

.fa-clock:before {
  content: "\F017"; }

.fa-clone:before {
  content: "\F24D"; }

.fa-closed-captioning:before {
  content: "\F20A"; }

.fa-cloud:before {
  content: "\F0C2"; }

.fa-cloud-download:before {
  content: "\F0ED"; }

.fa-cloud-download-alt:before {
  content: "\F381"; }

.fa-cloud-drizzle:before {
  content: "\F738"; }

.fa-cloud-hail:before {
  content: "\F739"; }

.fa-cloud-hail-mixed:before {
  content: "\F73A"; }

.fa-cloud-meatball:before {
  content: "\F73B"; }

.fa-cloud-moon:before {
  content: "\F6C3"; }

.fa-cloud-moon-rain:before {
  content: "\F73C"; }

.fa-cloud-rain:before {
  content: "\F73D"; }

.fa-cloud-rainbow:before {
  content: "\F73E"; }

.fa-cloud-showers:before {
  content: "\F73F"; }

.fa-cloud-showers-heavy:before {
  content: "\F740"; }

.fa-cloud-sleet:before {
  content: "\F741"; }

.fa-cloud-snow:before {
  content: "\F742"; }

.fa-cloud-sun:before {
  content: "\F6C4"; }

.fa-cloud-sun-rain:before {
  content: "\F743"; }

.fa-cloud-upload:before {
  content: "\F0EE"; }

.fa-cloud-upload-alt:before {
  content: "\F382"; }

.fa-clouds:before {
  content: "\F744"; }

.fa-clouds-moon:before {
  content: "\F745"; }

.fa-clouds-sun:before {
  content: "\F746"; }

.fa-cloudscale:before {
  content: "\F383"; }

.fa-cloudsmith:before {
  content: "\F384"; }

.fa-cloudversify:before {
  content: "\F385"; }

.fa-club:before {
  content: "\F327"; }

.fa-cocktail:before {
  content: "\F561"; }

.fa-code:before {
  content: "\F121"; }

.fa-code-branch:before {
  content: "\F126"; }

.fa-code-commit:before {
  content: "\F386"; }

.fa-code-merge:before {
  content: "\F387"; }

.fa-codepen:before {
  content: "\F1CB"; }

.fa-codiepie:before {
  content: "\F284"; }

.fa-coffee:before {
  content: "\F0F4"; }

.fa-coffee-togo:before {
  content: "\F6C5"; }

.fa-coffin:before {
  content: "\F6C6"; }

.fa-cog:before {
  content: "\F013"; }

.fa-cogs:before {
  content: "\F085"; }

.fa-coins:before {
  content: "\F51E"; }

.fa-columns:before {
  content: "\F0DB"; }

.fa-comment:before {
  content: "\F075"; }

.fa-comment-alt:before {
  content: "\F27A"; }

.fa-comment-alt-check:before {
  content: "\F4A2"; }

.fa-comment-alt-dollar:before {
  content: "\F650"; }

.fa-comment-alt-dots:before {
  content: "\F4A3"; }

.fa-comment-alt-edit:before {
  content: "\F4A4"; }

.fa-comment-alt-exclamation:before {
  content: "\F4A5"; }

.fa-comment-alt-lines:before {
  content: "\F4A6"; }

.fa-comment-alt-minus:before {
  content: "\F4A7"; }

.fa-comment-alt-plus:before {
  content: "\F4A8"; }

.fa-comment-alt-slash:before {
  content: "\F4A9"; }

.fa-comment-alt-smile:before {
  content: "\F4AA"; }

.fa-comment-alt-times:before {
  content: "\F4AB"; }

.fa-comment-check:before {
  content: "\F4AC"; }

.fa-comment-dollar:before {
  content: "\F651"; }

.fa-comment-dots:before {
  content: "\F4AD"; }

.fa-comment-edit:before {
  content: "\F4AE"; }

.fa-comment-exclamation:before {
  content: "\F4AF"; }

.fa-comment-lines:before {
  content: "\F4B0"; }

.fa-comment-minus:before {
  content: "\F4B1"; }

.fa-comment-plus:before {
  content: "\F4B2"; }

.fa-comment-slash:before {
  content: "\F4B3"; }

.fa-comment-smile:before {
  content: "\F4B4"; }

.fa-comment-times:before {
  content: "\F4B5"; }

.fa-comments:before {
  content: "\F086"; }

.fa-comments-alt:before {
  content: "\F4B6"; }

.fa-comments-alt-dollar:before {
  content: "\F652"; }

.fa-comments-dollar:before {
  content: "\F653"; }

.fa-compact-disc:before {
  content: "\F51F"; }

.fa-compass:before {
  content: "\F14E"; }

.fa-compass-slash:before {
  content: "\F5E9"; }

.fa-compress:before {
  content: "\F066"; }

.fa-compress-alt:before {
  content: "\F422"; }

.fa-compress-wide:before {
  content: "\F326"; }

.fa-concierge-bell:before {
  content: "\F562"; }

.fa-connectdevelop:before {
  content: "\F20E"; }

.fa-container-storage:before {
  content: "\F4B7"; }

.fa-contao:before {
  content: "\F26D"; }

.fa-conveyor-belt:before {
  content: "\F46E"; }

.fa-conveyor-belt-alt:before {
  content: "\F46F"; }

.fa-cookie:before {
  content: "\F563"; }

.fa-cookie-bite:before {
  content: "\F564"; }

.fa-copy:before {
  content: "\F0C5"; }

.fa-copyright:before {
  content: "\F1F9"; }

.fa-corn:before {
  content: "\F6C7"; }

.fa-couch:before {
  content: "\F4B8"; }

.fa-cow:before {
  content: "\F6C8"; }

.fa-cpanel:before {
  content: "\F388"; }

.fa-creative-commons:before {
  content: "\F25E"; }

.fa-creative-commons-by:before {
  content: "\F4E7"; }

.fa-creative-commons-nc:before {
  content: "\F4E8"; }

.fa-creative-commons-nc-eu:before {
  content: "\F4E9"; }

.fa-creative-commons-nc-jp:before {
  content: "\F4EA"; }

.fa-creative-commons-nd:before {
  content: "\F4EB"; }

.fa-creative-commons-pd:before {
  content: "\F4EC"; }

.fa-creative-commons-pd-alt:before {
  content: "\F4ED"; }

.fa-creative-commons-remix:before {
  content: "\F4EE"; }

.fa-creative-commons-sa:before {
  content: "\F4EF"; }

.fa-creative-commons-sampling:before {
  content: "\F4F0"; }

.fa-creative-commons-sampling-plus:before {
  content: "\F4F1"; }

.fa-creative-commons-share:before {
  content: "\F4F2"; }

.fa-creative-commons-zero:before {
  content: "\F4F3"; }

.fa-credit-card:before {
  content: "\F09D"; }

.fa-credit-card-blank:before {
  content: "\F389"; }

.fa-credit-card-front:before {
  content: "\F38A"; }

.fa-cricket:before {
  content: "\F449"; }

.fa-critical-role:before {
  content: "\F6C9"; }

.fa-crop:before {
  content: "\F125"; }

.fa-crop-alt:before {
  content: "\F565"; }

.fa-cross:before {
  content: "\F654"; }

.fa-crosshairs:before {
  content: "\F05B"; }

.fa-crow:before {
  content: "\F520"; }

.fa-crown:before {
  content: "\F521"; }

.fa-css3:before {
  content: "\F13C"; }

.fa-css3-alt:before {
  content: "\F38B"; }

.fa-cube:before {
  content: "\F1B2"; }

.fa-cubes:before {
  content: "\F1B3"; }

.fa-curling:before {
  content: "\F44A"; }

.fa-cut:before {
  content: "\F0C4"; }

.fa-cuttlefish:before {
  content: "\F38C"; }

.fa-d-and-d:before {
  content: "\F38D"; }

.fa-d-and-d-beyond:before {
  content: "\F6CA"; }

.fa-dagger:before {
  content: "\F6CB"; }

.fa-dashcube:before {
  content: "\F210"; }

.fa-database:before {
  content: "\F1C0"; }

.fa-deaf:before {
  content: "\F2A4"; }

.fa-delicious:before {
  content: "\F1A5"; }

.fa-democrat:before {
  content: "\F747"; }

.fa-deploydog:before {
  content: "\F38E"; }

.fa-deskpro:before {
  content: "\F38F"; }

.fa-desktop:before {
  content: "\F108"; }

.fa-desktop-alt:before {
  content: "\F390"; }

.fa-dev:before {
  content: "\F6CC"; }

.fa-deviantart:before {
  content: "\F1BD"; }

.fa-dewpoint:before {
  content: "\F748"; }

.fa-dharmachakra:before {
  content: "\F655"; }

.fa-diagnoses:before {
  content: "\F470"; }

.fa-diamond:before {
  content: "\F219"; }

.fa-dice:before {
  content: "\F522"; }

.fa-dice-d10:before {
  content: "\F6CD"; }

.fa-dice-d12:before {
  content: "\F6CE"; }

.fa-dice-d20:before {
  content: "\F6CF"; }

.fa-dice-d4:before {
  content: "\F6D0"; }

.fa-dice-d6:before {
  content: "\F6D1"; }

.fa-dice-d8:before {
  content: "\F6D2"; }

.fa-dice-five:before {
  content: "\F523"; }

.fa-dice-four:before {
  content: "\F524"; }

.fa-dice-one:before {
  content: "\F525"; }

.fa-dice-six:before {
  content: "\F526"; }

.fa-dice-three:before {
  content: "\F527"; }

.fa-dice-two:before {
  content: "\F528"; }

.fa-digg:before {
  content: "\F1A6"; }

.fa-digital-ocean:before {
  content: "\F391"; }

.fa-digital-tachograph:before {
  content: "\F566"; }

.fa-diploma:before {
  content: "\F5EA"; }

.fa-directions:before {
  content: "\F5EB"; }

.fa-discord:before {
  content: "\F392"; }

.fa-discourse:before {
  content: "\F393"; }

.fa-divide:before {
  content: "\F529"; }

.fa-dizzy:before {
  content: "\F567"; }

.fa-dna:before {
  content: "\F471"; }

.fa-do-not-enter:before {
  content: "\F5EC"; }

.fa-dochub:before {
  content: "\F394"; }

.fa-docker:before {
  content: "\F395"; }

.fa-dog:before {
  content: "\F6D3"; }

.fa-dog-leashed:before {
  content: "\F6D4"; }

.fa-dollar-sign:before {
  content: "\F155"; }

.fa-dolly:before {
  content: "\F472"; }

.fa-dolly-empty:before {
  content: "\F473"; }

.fa-dolly-flatbed:before {
  content: "\F474"; }

.fa-dolly-flatbed-alt:before {
  content: "\F475"; }

.fa-dolly-flatbed-empty:before {
  content: "\F476"; }

.fa-donate:before {
  content: "\F4B9"; }

.fa-door-closed:before {
  content: "\F52A"; }

.fa-door-open:before {
  content: "\F52B"; }

.fa-dot-circle:before {
  content: "\F192"; }

.fa-dove:before {
  content: "\F4BA"; }

.fa-download:before {
  content: "\F019"; }

.fa-draft2digital:before {
  content: "\F396"; }

.fa-drafting-compass:before {
  content: "\F568"; }

.fa-dragon:before {
  content: "\F6D5"; }

.fa-draw-circle:before {
  content: "\F5ED"; }

.fa-draw-polygon:before {
  content: "\F5EE"; }

.fa-draw-square:before {
  content: "\F5EF"; }

.fa-dribbble:before {
  content: "\F17D"; }

.fa-dribbble-square:before {
  content: "\F397"; }

.fa-dropbox:before {
  content: "\F16B"; }

.fa-drum:before {
  content: "\F569"; }

.fa-drum-steelpan:before {
  content: "\F56A"; }

.fa-drumstick:before {
  content: "\F6D6"; }

.fa-drumstick-bite:before {
  content: "\F6D7"; }

.fa-drupal:before {
  content: "\F1A9"; }

.fa-duck:before {
  content: "\F6D8"; }

.fa-dumbbell:before {
  content: "\F44B"; }

.fa-dungeon:before {
  content: "\F6D9"; }

.fa-dyalog:before {
  content: "\F399"; }

.fa-ear:before {
  content: "\F5F0"; }

.fa-earlybirds:before {
  content: "\F39A"; }

.fa-ebay:before {
  content: "\F4F4"; }

.fa-eclipse:before {
  content: "\F749"; }

.fa-eclipse-alt:before {
  content: "\F74A"; }

.fa-edge:before {
  content: "\F282"; }

.fa-edit:before {
  content: "\F044"; }

.fa-eject:before {
  content: "\F052"; }

.fa-elementor:before {
  content: "\F430"; }

.fa-elephant:before {
  content: "\F6DA"; }

.fa-ellipsis-h:before {
  content: "\F141"; }

.fa-ellipsis-h-alt:before {
  content: "\F39B"; }

.fa-ellipsis-v:before {
  content: "\F142"; }

.fa-ellipsis-v-alt:before {
  content: "\F39C"; }

.fa-ello:before {
  content: "\F5F1"; }

.fa-ember:before {
  content: "\F423"; }

.fa-empire:before {
  content: "\F1D1"; }

.fa-empty-set:before {
  content: "\F656"; }

.fa-engine-warning:before {
  content: "\F5F2"; }

.fa-envelope:before {
  content: "\F0E0"; }

.fa-envelope-open:before {
  content: "\F2B6"; }

.fa-envelope-open-dollar:before {
  content: "\F657"; }

.fa-envelope-open-text:before {
  content: "\F658"; }

.fa-envelope-square:before {
  content: "\F199"; }

.fa-envira:before {
  content: "\F299"; }

.fa-equals:before {
  content: "\F52C"; }

.fa-eraser:before {
  content: "\F12D"; }

.fa-erlang:before {
  content: "\F39D"; }

.fa-ethereum:before {
  content: "\F42E"; }

.fa-etsy:before {
  content: "\F2D7"; }

.fa-euro-sign:before {
  content: "\F153"; }

.fa-exchange:before {
  content: "\F0EC"; }

.fa-exchange-alt:before {
  content: "\F362"; }

.fa-exclamation:before {
  content: "\F12A"; }

.fa-exclamation-circle:before {
  content: "\F06A"; }

.fa-exclamation-square:before {
  content: "\F321"; }

.fa-exclamation-triangle:before {
  content: "\F071"; }

.fa-expand:before {
  content: "\F065"; }

.fa-expand-alt:before {
  content: "\F424"; }

.fa-expand-arrows:before {
  content: "\F31D"; }

.fa-expand-arrows-alt:before {
  content: "\F31E"; }

.fa-expand-wide:before {
  content: "\F320"; }

.fa-expeditedssl:before {
  content: "\F23E"; }

.fa-external-link:before {
  content: "\F08E"; }

.fa-external-link-alt:before {
  content: "\F35D"; }

.fa-external-link-square:before {
  content: "\F14C"; }

.fa-external-link-square-alt:before {
  content: "\F360"; }

.fa-eye:before {
  content: "\F06E"; }

.fa-eye-dropper:before {
  content: "\F1FB"; }

.fa-eye-evil:before {
  content: "\F6DB"; }

.fa-eye-slash:before {
  content: "\F070"; }

.fa-facebook:before {
  content: "\F09A"; }

.fa-facebook-f:before {
  content: "\F39E"; }

.fa-facebook-messenger:before {
  content: "\F39F"; }

.fa-facebook-square:before {
  content: "\F082"; }

.fa-fantasy-flight-games:before {
  content: "\F6DC"; }

.fa-fast-backward:before {
  content: "\F049"; }

.fa-fast-forward:before {
  content: "\F050"; }

.fa-fax:before {
  content: "\F1AC"; }

.fa-feather:before {
  content: "\F52D"; }

.fa-feather-alt:before {
  content: "\F56B"; }

.fa-female:before {
  content: "\F182"; }

.fa-field-hockey:before {
  content: "\F44C"; }

.fa-fighter-jet:before {
  content: "\F0FB"; }

.fa-file:before {
  content: "\F15B"; }

.fa-file-alt:before {
  content: "\F15C"; }

.fa-file-archive:before {
  content: "\F1C6"; }

.fa-file-audio:before {
  content: "\F1C7"; }

.fa-file-certificate:before {
  content: "\F5F3"; }

.fa-file-chart-line:before {
  content: "\F659"; }

.fa-file-chart-pie:before {
  content: "\F65A"; }

.fa-file-check:before {
  content: "\F316"; }

.fa-file-code:before {
  content: "\F1C9"; }

.fa-file-contract:before {
  content: "\F56C"; }

.fa-file-csv:before {
  content: "\F6DD"; }

.fa-file-download:before {
  content: "\F56D"; }

.fa-file-edit:before {
  content: "\F31C"; }

.fa-file-excel:before {
  content: "\F1C3"; }

.fa-file-exclamation:before {
  content: "\F31A"; }

.fa-file-export:before {
  content: "\F56E"; }

.fa-file-image:before {
  content: "\F1C5"; }

.fa-file-import:before {
  content: "\F56F"; }

.fa-file-invoice:before {
  content: "\F570"; }

.fa-file-invoice-dollar:before {
  content: "\F571"; }

.fa-file-medical:before {
  content: "\F477"; }

.fa-file-medical-alt:before {
  content: "\F478"; }

.fa-file-minus:before {
  content: "\F318"; }

.fa-file-pdf:before {
  content: "\F1C1"; }

.fa-file-plus:before {
  content: "\F319"; }

.fa-file-powerpoint:before {
  content: "\F1C4"; }

.fa-file-prescription:before {
  content: "\F572"; }

.fa-file-signature:before {
  content: "\F573"; }

.fa-file-spreadsheet:before {
  content: "\F65B"; }

.fa-file-times:before {
  content: "\F317"; }

.fa-file-upload:before {
  content: "\F574"; }

.fa-file-user:before {
  content: "\F65C"; }

.fa-file-video:before {
  content: "\F1C8"; }

.fa-file-word:before {
  content: "\F1C2"; }

.fa-fill:before {
  content: "\F575"; }

.fa-fill-drip:before {
  content: "\F576"; }

.fa-film:before {
  content: "\F008"; }

.fa-film-alt:before {
  content: "\F3A0"; }

.fa-filter:before {
  content: "\F0B0"; }

.fa-fingerprint:before {
  content: "\F577"; }

.fa-fire:before {
  content: "\F06D"; }

.fa-fire-extinguisher:before {
  content: "\F134"; }

.fa-fire-smoke:before {
  content: "\F74B"; }

.fa-firefox:before {
  content: "\F269"; }

.fa-first-aid:before {
  content: "\F479"; }

.fa-first-order:before {
  content: "\F2B0"; }

.fa-first-order-alt:before {
  content: "\F50A"; }

.fa-firstdraft:before {
  content: "\F3A1"; }

.fa-fish:before {
  content: "\F578"; }

.fa-fist-raised:before {
  content: "\F6DE"; }

.fa-flag:before {
  content: "\F024"; }

.fa-flag-alt:before {
  content: "\F74C"; }

.fa-flag-checkered:before {
  content: "\F11E"; }

.fa-flag-usa:before {
  content: "\F74D"; }

.fa-flame:before {
  content: "\F6DF"; }

.fa-flask:before {
  content: "\F0C3"; }

.fa-flask-poison:before {
  content: "\F6E0"; }

.fa-flask-potion:before {
  content: "\F6E1"; }

.fa-flickr:before {
  content: "\F16E"; }

.fa-flipboard:before {
  content: "\F44D"; }

.fa-flushed:before {
  content: "\F579"; }

.fa-fly:before {
  content: "\F417"; }

.fa-fog:before {
  content: "\F74E"; }

.fa-folder:before {
  content: "\F07B"; }

.fa-folder-minus:before {
  content: "\F65D"; }

.fa-folder-open:before {
  content: "\F07C"; }

.fa-folder-plus:before {
  content: "\F65E"; }

.fa-folder-times:before {
  content: "\F65F"; }

.fa-folders:before {
  content: "\F660"; }

.fa-font:before {
  content: "\F031"; }

.fa-font-awesome:before {
  content: "\F2B4"; }

.fa-font-awesome-alt:before {
  content: "\F35C"; }

.fa-font-awesome-flag:before {
  content: "\F425"; }

.fa-font-awesome-logo-full:before {
  content: "\F4E6"; }

.fa-fonticons:before {
  content: "\F280"; }

.fa-fonticons-fi:before {
  content: "\F3A2"; }

.fa-football-ball:before {
  content: "\F44E"; }

.fa-football-helmet:before {
  content: "\F44F"; }

.fa-forklift:before {
  content: "\F47A"; }

.fa-fort-awesome:before {
  content: "\F286"; }

.fa-fort-awesome-alt:before {
  content: "\F3A3"; }

.fa-forumbee:before {
  content: "\F211"; }

.fa-forward:before {
  content: "\F04E"; }

.fa-foursquare:before {
  content: "\F180"; }

.fa-fragile:before {
  content: "\F4BB"; }

.fa-free-code-camp:before {
  content: "\F2C5"; }

.fa-freebsd:before {
  content: "\F3A4"; }

.fa-frog:before {
  content: "\F52E"; }

.fa-frown:before {
  content: "\F119"; }

.fa-frown-open:before {
  content: "\F57A"; }

.fa-fulcrum:before {
  content: "\F50B"; }

.fa-function:before {
  content: "\F661"; }

.fa-funnel-dollar:before {
  content: "\F662"; }

.fa-futbol:before {
  content: "\F1E3"; }

.fa-galactic-republic:before {
  content: "\F50C"; }

.fa-galactic-senate:before {
  content: "\F50D"; }

.fa-gamepad:before {
  content: "\F11B"; }

.fa-gas-pump:before {
  content: "\F52F"; }

.fa-gas-pump-slash:before {
  content: "\F5F4"; }

.fa-gavel:before {
  content: "\F0E3"; }

.fa-gem:before {
  content: "\F3A5"; }

.fa-genderless:before {
  content: "\F22D"; }

.fa-get-pocket:before {
  content: "\F265"; }

.fa-gg:before {
  content: "\F260"; }

.fa-gg-circle:before {
  content: "\F261"; }

.fa-ghost:before {
  content: "\F6E2"; }

.fa-gift:before {
  content: "\F06B"; }

.fa-gift-card:before {
  content: "\F663"; }

.fa-git:before {
  content: "\F1D3"; }

.fa-git-square:before {
  content: "\F1D2"; }

.fa-github:before {
  content: "\F09B"; }

.fa-github-alt:before {
  content: "\F113"; }

.fa-github-square:before {
  content: "\F092"; }

.fa-gitkraken:before {
  content: "\F3A6"; }

.fa-gitlab:before {
  content: "\F296"; }

.fa-gitter:before {
  content: "\F426"; }

.fa-glass-martini:before {
  content: "\F000"; }

.fa-glass-martini-alt:before {
  content: "\F57B"; }

.fa-glasses:before {
  content: "\F530"; }

.fa-glasses-alt:before {
  content: "\F5F5"; }

.fa-glide:before {
  content: "\F2A5"; }

.fa-glide-g:before {
  content: "\F2A6"; }

.fa-globe:before {
  content: "\F0AC"; }

.fa-globe-africa:before {
  content: "\F57C"; }

.fa-globe-americas:before {
  content: "\F57D"; }

.fa-globe-asia:before {
  content: "\F57E"; }

.fa-globe-stand:before {
  content: "\F5F6"; }

.fa-gofore:before {
  content: "\F3A7"; }

.fa-golf-ball:before {
  content: "\F450"; }

.fa-golf-club:before {
  content: "\F451"; }

.fa-goodreads:before {
  content: "\F3A8"; }

.fa-goodreads-g:before {
  content: "\F3A9"; }

.fa-google:before {
  content: "\F1A0"; }

.fa-google-drive:before {
  content: "\F3AA"; }

.fa-google-play:before {
  content: "\F3AB"; }

.fa-google-plus:before {
  content: "\F2B3"; }

.fa-google-plus-g:before {
  content: "\F0D5"; }

.fa-google-plus-square:before {
  content: "\F0D4"; }

.fa-google-wallet:before {
  content: "\F1EE"; }

.fa-gopuram:before {
  content: "\F664"; }

.fa-graduation-cap:before {
  content: "\F19D"; }

.fa-gratipay:before {
  content: "\F184"; }

.fa-grav:before {
  content: "\F2D6"; }

.fa-greater-than:before {
  content: "\F531"; }

.fa-greater-than-equal:before {
  content: "\F532"; }

.fa-grimace:before {
  content: "\F57F"; }

.fa-grin:before {
  content: "\F580"; }

.fa-grin-alt:before {
  content: "\F581"; }

.fa-grin-beam:before {
  content: "\F582"; }

.fa-grin-beam-sweat:before {
  content: "\F583"; }

.fa-grin-hearts:before {
  content: "\F584"; }

.fa-grin-squint:before {
  content: "\F585"; }

.fa-grin-squint-tears:before {
  content: "\F586"; }

.fa-grin-stars:before {
  content: "\F587"; }

.fa-grin-tears:before {
  content: "\F588"; }

.fa-grin-tongue:before {
  content: "\F589"; }

.fa-grin-tongue-squint:before {
  content: "\F58A"; }

.fa-grin-tongue-wink:before {
  content: "\F58B"; }

.fa-grin-wink:before {
  content: "\F58C"; }

.fa-grip-horizontal:before {
  content: "\F58D"; }

.fa-grip-vertical:before {
  content: "\F58E"; }

.fa-gripfire:before {
  content: "\F3AC"; }

.fa-grunt:before {
  content: "\F3AD"; }

.fa-gulp:before {
  content: "\F3AE"; }

.fa-h-square:before {
  content: "\F0FD"; }

.fa-h1:before {
  content: "\F313"; }

.fa-h2:before {
  content: "\F314"; }

.fa-h3:before {
  content: "\F315"; }

.fa-hacker-news:before {
  content: "\F1D4"; }

.fa-hacker-news-square:before {
  content: "\F3AF"; }

.fa-hackerrank:before {
  content: "\F5F7"; }

.fa-hammer:before {
  content: "\F6E3"; }

.fa-hammer-war:before {
  content: "\F6E4"; }

.fa-hamsa:before {
  content: "\F665"; }

.fa-hand-heart:before {
  content: "\F4BC"; }

.fa-hand-holding:before {
  content: "\F4BD"; }

.fa-hand-holding-box:before {
  content: "\F47B"; }

.fa-hand-holding-heart:before {
  content: "\F4BE"; }

.fa-hand-holding-magic:before {
  content: "\F6E5"; }

.fa-hand-holding-seedling:before {
  content: "\F4BF"; }

.fa-hand-holding-usd:before {
  content: "\F4C0"; }

.fa-hand-holding-water:before {
  content: "\F4C1"; }

.fa-hand-lizard:before {
  content: "\F258"; }

.fa-hand-paper:before {
  content: "\F256"; }

.fa-hand-peace:before {
  content: "\F25B"; }

.fa-hand-point-down:before {
  content: "\F0A7"; }

.fa-hand-point-left:before {
  content: "\F0A5"; }

.fa-hand-point-right:before {
  content: "\F0A4"; }

.fa-hand-point-up:before {
  content: "\F0A6"; }

.fa-hand-pointer:before {
  content: "\F25A"; }

.fa-hand-receiving:before {
  content: "\F47C"; }

.fa-hand-rock:before {
  content: "\F255"; }

.fa-hand-scissors:before {
  content: "\F257"; }

.fa-hand-spock:before {
  content: "\F259"; }

.fa-hands:before {
  content: "\F4C2"; }

.fa-hands-heart:before {
  content: "\F4C3"; }

.fa-hands-helping:before {
  content: "\F4C4"; }

.fa-hands-usd:before {
  content: "\F4C5"; }

.fa-handshake:before {
  content: "\F2B5"; }

.fa-handshake-alt:before {
  content: "\F4C6"; }

.fa-hanukiah:before {
  content: "\F6E6"; }

.fa-hashtag:before {
  content: "\F292"; }

.fa-hat-witch:before {
  content: "\F6E7"; }

.fa-hat-wizard:before {
  content: "\F6E8"; }

.fa-haykal:before {
  content: "\F666"; }

.fa-hdd:before {
  content: "\F0A0"; }

.fa-head-side:before {
  content: "\F6E9"; }

.fa-head-vr:before {
  content: "\F6EA"; }

.fa-heading:before {
  content: "\F1DC"; }

.fa-headphones:before {
  content: "\F025"; }

.fa-headphones-alt:before {
  content: "\F58F"; }

.fa-headset:before {
  content: "\F590"; }

.fa-heart:before {
  content: "\F004"; }

.fa-heart-circle:before {
  content: "\F4C7"; }

.fa-heart-rate:before {
  content: "\F5F8"; }

.fa-heart-square:before {
  content: "\F4C8"; }

.fa-heartbeat:before {
  content: "\F21E"; }

.fa-helicopter:before {
  content: "\F533"; }

.fa-helmet-battle:before {
  content: "\F6EB"; }

.fa-hexagon:before {
  content: "\F312"; }

.fa-highlighter:before {
  content: "\F591"; }

.fa-hiking:before {
  content: "\F6EC"; }

.fa-hippo:before {
  content: "\F6ED"; }

.fa-hips:before {
  content: "\F452"; }

.fa-hire-a-helper:before {
  content: "\F3B0"; }

.fa-history:before {
  content: "\F1DA"; }

.fa-hockey-mask:before {
  content: "\F6EE"; }

.fa-hockey-puck:before {
  content: "\F453"; }

.fa-hockey-sticks:before {
  content: "\F454"; }

.fa-home:before {
  content: "\F015"; }

.fa-home-heart:before {
  content: "\F4C9"; }

.fa-hood-cloak:before {
  content: "\F6EF"; }

.fa-hooli:before {
  content: "\F427"; }

.fa-hornbill:before {
  content: "\F592"; }

.fa-horse:before {
  content: "\F6F0"; }

.fa-hospital:before {
  content: "\F0F8"; }

.fa-hospital-alt:before {
  content: "\F47D"; }

.fa-hospital-symbol:before {
  content: "\F47E"; }

.fa-hot-tub:before {
  content: "\F593"; }

.fa-hotel:before {
  content: "\F594"; }

.fa-hotjar:before {
  content: "\F3B1"; }

.fa-hourglass:before {
  content: "\F254"; }

.fa-hourglass-end:before {
  content: "\F253"; }

.fa-hourglass-half:before {
  content: "\F252"; }

.fa-hourglass-start:before {
  content: "\F251"; }

.fa-house-damage:before {
  content: "\F6F1"; }

.fa-house-flood:before {
  content: "\F74F"; }

.fa-houzz:before {
  content: "\F27C"; }

.fa-hryvnia:before {
  content: "\F6F2"; }

.fa-html5:before {
  content: "\F13B"; }

.fa-hubspot:before {
  content: "\F3B2"; }

.fa-humidity:before {
  content: "\F750"; }

.fa-hurricane:before {
  content: "\F751"; }

.fa-i-cursor:before {
  content: "\F246"; }

.fa-id-badge:before {
  content: "\F2C1"; }

.fa-id-card:before {
  content: "\F2C2"; }

.fa-id-card-alt:before {
  content: "\F47F"; }

.fa-image:before {
  content: "\F03E"; }

.fa-images:before {
  content: "\F302"; }

.fa-imdb:before {
  content: "\F2D8"; }

.fa-inbox:before {
  content: "\F01C"; }

.fa-inbox-in:before {
  content: "\F310"; }

.fa-inbox-out:before {
  content: "\F311"; }

.fa-indent:before {
  content: "\F03C"; }

.fa-industry:before {
  content: "\F275"; }

.fa-industry-alt:before {
  content: "\F3B3"; }

.fa-infinity:before {
  content: "\F534"; }

.fa-info:before {
  content: "\F129"; }

.fa-info-circle:before {
  content: "\F05A"; }

.fa-info-square:before {
  content: "\F30F"; }

.fa-inhaler:before {
  content: "\F5F9"; }

.fa-instagram:before {
  content: "\F16D"; }

.fa-integral:before {
  content: "\F667"; }

.fa-internet-explorer:before {
  content: "\F26B"; }

.fa-intersection:before {
  content: "\F668"; }

.fa-inventory:before {
  content: "\F480"; }

.fa-ioxhost:before {
  content: "\F208"; }

.fa-italic:before {
  content: "\F033"; }

.fa-itunes:before {
  content: "\F3B4"; }

.fa-itunes-note:before {
  content: "\F3B5"; }

.fa-jack-o-lantern:before {
  content: "\F30E"; }

.fa-java:before {
  content: "\F4E4"; }

.fa-jedi:before {
  content: "\F669"; }

.fa-jedi-order:before {
  content: "\F50E"; }

.fa-jenkins:before {
  content: "\F3B6"; }

.fa-joget:before {
  content: "\F3B7"; }

.fa-joint:before {
  content: "\F595"; }

.fa-joomla:before {
  content: "\F1AA"; }

.fa-journal-whills:before {
  content: "\F66A"; }

.fa-js:before {
  content: "\F3B8"; }

.fa-js-square:before {
  content: "\F3B9"; }

.fa-jsfiddle:before {
  content: "\F1CC"; }

.fa-kaaba:before {
  content: "\F66B"; }

.fa-kaggle:before {
  content: "\F5FA"; }

.fa-key:before {
  content: "\F084"; }

.fa-key-skeleton:before {
  content: "\F6F3"; }

.fa-keybase:before {
  content: "\F4F5"; }

.fa-keyboard:before {
  content: "\F11C"; }

.fa-keycdn:before {
  content: "\F3BA"; }

.fa-keynote:before {
  content: "\F66C"; }

.fa-khanda:before {
  content: "\F66D"; }

.fa-kickstarter:before {
  content: "\F3BB"; }

.fa-kickstarter-k:before {
  content: "\F3BC"; }

.fa-kidneys:before {
  content: "\F5FB"; }

.fa-kiss:before {
  content: "\F596"; }

.fa-kiss-beam:before {
  content: "\F597"; }

.fa-kiss-wink-heart:before {
  content: "\F598"; }

.fa-kite:before {
  content: "\F6F4"; }

.fa-kiwi-bird:before {
  content: "\F535"; }

.fa-knife-kitchen:before {
  content: "\F6F5"; }

.fa-korvue:before {
  content: "\F42F"; }

.fa-lambda:before {
  content: "\F66E"; }

.fa-lamp:before {
  content: "\F4CA"; }

.fa-landmark:before {
  content: "\F66F"; }

.fa-landmark-alt:before {
  content: "\F752"; }

.fa-language:before {
  content: "\F1AB"; }

.fa-laptop:before {
  content: "\F109"; }

.fa-laptop-code:before {
  content: "\F5FC"; }

.fa-laravel:before {
  content: "\F3BD"; }

.fa-lastfm:before {
  content: "\F202"; }

.fa-lastfm-square:before {
  content: "\F203"; }

.fa-laugh:before {
  content: "\F599"; }

.fa-laugh-beam:before {
  content: "\F59A"; }

.fa-laugh-squint:before {
  content: "\F59B"; }

.fa-laugh-wink:before {
  content: "\F59C"; }

.fa-layer-group:before {
  content: "\F5FD"; }

.fa-layer-minus:before {
  content: "\F5FE"; }

.fa-layer-plus:before {
  content: "\F5FF"; }

.fa-leaf:before {
  content: "\F06C"; }

.fa-leaf-heart:before {
  content: "\F4CB"; }

.fa-leaf-maple:before {
  content: "\F6F6"; }

.fa-leaf-oak:before {
  content: "\F6F7"; }

.fa-leanpub:before {
  content: "\F212"; }

.fa-lemon:before {
  content: "\F094"; }

.fa-less:before {
  content: "\F41D"; }

.fa-less-than:before {
  content: "\F536"; }

.fa-less-than-equal:before {
  content: "\F537"; }

.fa-level-down:before {
  content: "\F149"; }

.fa-level-down-alt:before {
  content: "\F3BE"; }

.fa-level-up:before {
  content: "\F148"; }

.fa-level-up-alt:before {
  content: "\F3BF"; }

.fa-life-ring:before {
  content: "\F1CD"; }

.fa-lightbulb:before {
  content: "\F0EB"; }

.fa-lightbulb-dollar:before {
  content: "\F670"; }

.fa-lightbulb-exclamation:before {
  content: "\F671"; }

.fa-lightbulb-on:before {
  content: "\F672"; }

.fa-lightbulb-slash:before {
  content: "\F673"; }

.fa-line:before {
  content: "\F3C0"; }

.fa-link:before {
  content: "\F0C1"; }

.fa-linkedin:before {
  content: "\F08C"; }

.fa-linkedin-in:before {
  content: "\F0E1"; }

.fa-linode:before {
  content: "\F2B8"; }

.fa-linux:before {
  content: "\F17C"; }

.fa-lips:before {
  content: "\F600"; }

.fa-lira-sign:before {
  content: "\F195"; }

.fa-list:before {
  content: "\F03A"; }

.fa-list-alt:before {
  content: "\F022"; }

.fa-list-ol:before {
  content: "\F0CB"; }

.fa-list-ul:before {
  content: "\F0CA"; }

.fa-location:before {
  content: "\F601"; }

.fa-location-arrow:before {
  content: "\F124"; }

.fa-location-circle:before {
  content: "\F602"; }

.fa-location-slash:before {
  content: "\F603"; }

.fa-lock:before {
  content: "\F023"; }

.fa-lock-alt:before {
  content: "\F30D"; }

.fa-lock-open:before {
  content: "\F3C1"; }

.fa-lock-open-alt:before {
  content: "\F3C2"; }

.fa-long-arrow-alt-down:before {
  content: "\F309"; }

.fa-long-arrow-alt-left:before {
  content: "\F30A"; }

.fa-long-arrow-alt-right:before {
  content: "\F30B"; }

.fa-long-arrow-alt-up:before {
  content: "\F30C"; }

.fa-long-arrow-down:before {
  content: "\F175"; }

.fa-long-arrow-left:before {
  content: "\F177"; }

.fa-long-arrow-right:before {
  content: "\F178"; }

.fa-long-arrow-up:before {
  content: "\F176"; }

.fa-loveseat:before {
  content: "\F4CC"; }

.fa-low-vision:before {
  content: "\F2A8"; }

.fa-luchador:before {
  content: "\F455"; }

.fa-luggage-cart:before {
  content: "\F59D"; }

.fa-lungs:before {
  content: "\F604"; }

.fa-lyft:before {
  content: "\F3C3"; }

.fa-mace:before {
  content: "\F6F8"; }

.fa-magento:before {
  content: "\F3C4"; }

.fa-magic:before {
  content: "\F0D0"; }

.fa-magnet:before {
  content: "\F076"; }

.fa-mail-bulk:before {
  content: "\F674"; }

.fa-mailchimp:before {
  content: "\F59E"; }

.fa-male:before {
  content: "\F183"; }

.fa-mandalorian:before {
  content: "\F50F"; }

.fa-mandolin:before {
  content: "\F6F9"; }

.fa-map:before {
  content: "\F279"; }

.fa-map-marked:before {
  content: "\F59F"; }

.fa-map-marked-alt:before {
  content: "\F5A0"; }

.fa-map-marker:before {
  content: "\F041"; }

.fa-map-marker-alt:before {
  content: "\F3C5"; }

.fa-map-marker-alt-slash:before {
  content: "\F605"; }

.fa-map-marker-check:before {
  content: "\F606"; }

.fa-map-marker-edit:before {
  content: "\F607"; }

.fa-map-marker-exclamation:before {
  content: "\F608"; }

.fa-map-marker-minus:before {
  content: "\F609"; }

.fa-map-marker-plus:before {
  content: "\F60A"; }

.fa-map-marker-question:before {
  content: "\F60B"; }

.fa-map-marker-slash:before {
  content: "\F60C"; }

.fa-map-marker-smile:before {
  content: "\F60D"; }

.fa-map-marker-times:before {
  content: "\F60E"; }

.fa-map-pin:before {
  content: "\F276"; }

.fa-map-signs:before {
  content: "\F277"; }

.fa-markdown:before {
  content: "\F60F"; }

.fa-marker:before {
  content: "\F5A1"; }

.fa-mars:before {
  content: "\F222"; }

.fa-mars-double:before {
  content: "\F227"; }

.fa-mars-stroke:before {
  content: "\F229"; }

.fa-mars-stroke-h:before {
  content: "\F22B"; }

.fa-mars-stroke-v:before {
  content: "\F22A"; }

.fa-mask:before {
  content: "\F6FA"; }

.fa-mastodon:before {
  content: "\F4F6"; }

.fa-maxcdn:before {
  content: "\F136"; }

.fa-medal:before {
  content: "\F5A2"; }

.fa-medapps:before {
  content: "\F3C6"; }

.fa-medium:before {
  content: "\F23A"; }

.fa-medium-m:before {
  content: "\F3C7"; }

.fa-medkit:before {
  content: "\F0FA"; }

.fa-medrt:before {
  content: "\F3C8"; }

.fa-meetup:before {
  content: "\F2E0"; }

.fa-megaphone:before {
  content: "\F675"; }

.fa-megaport:before {
  content: "\F5A3"; }

.fa-meh:before {
  content: "\F11A"; }

.fa-meh-blank:before {
  content: "\F5A4"; }

.fa-meh-rolling-eyes:before {
  content: "\F5A5"; }

.fa-memory:before {
  content: "\F538"; }

.fa-menorah:before {
  content: "\F676"; }

.fa-mercury:before {
  content: "\F223"; }

.fa-meteor:before {
  content: "\F753"; }

.fa-microchip:before {
  content: "\F2DB"; }

.fa-microphone:before {
  content: "\F130"; }

.fa-microphone-alt:before {
  content: "\F3C9"; }

.fa-microphone-alt-slash:before {
  content: "\F539"; }

.fa-microphone-slash:before {
  content: "\F131"; }

.fa-microscope:before {
  content: "\F610"; }

.fa-microsoft:before {
  content: "\F3CA"; }

.fa-mind-share:before {
  content: "\F677"; }

.fa-minus:before {
  content: "\F068"; }

.fa-minus-circle:before {
  content: "\F056"; }

.fa-minus-hexagon:before {
  content: "\F307"; }

.fa-minus-octagon:before {
  content: "\F308"; }

.fa-minus-square:before {
  content: "\F146"; }

.fa-mix:before {
  content: "\F3CB"; }

.fa-mixcloud:before {
  content: "\F289"; }

.fa-mizuni:before {
  content: "\F3CC"; }

.fa-mobile:before {
  content: "\F10B"; }

.fa-mobile-alt:before {
  content: "\F3CD"; }

.fa-mobile-android:before {
  content: "\F3CE"; }

.fa-mobile-android-alt:before {
  content: "\F3CF"; }

.fa-modx:before {
  content: "\F285"; }

.fa-monero:before {
  content: "\F3D0"; }

.fa-money-bill:before {
  content: "\F0D6"; }

.fa-money-bill-alt:before {
  content: "\F3D1"; }

.fa-money-bill-wave:before {
  content: "\F53A"; }

.fa-money-bill-wave-alt:before {
  content: "\F53B"; }

.fa-money-check:before {
  content: "\F53C"; }

.fa-money-check-alt:before {
  content: "\F53D"; }

.fa-monitor-heart-rate:before {
  content: "\F611"; }

.fa-monkey:before {
  content: "\F6FB"; }

.fa-monument:before {
  content: "\F5A6"; }

.fa-moon:before {
  content: "\F186"; }

.fa-moon-cloud:before {
  content: "\F754"; }

.fa-moon-stars:before {
  content: "\F755"; }

.fa-mortar-pestle:before {
  content: "\F5A7"; }

.fa-mosque:before {
  content: "\F678"; }

.fa-motorcycle:before {
  content: "\F21C"; }

.fa-mountain:before {
  content: "\F6FC"; }

.fa-mountains:before {
  content: "\F6FD"; }

.fa-mouse-pointer:before {
  content: "\F245"; }

.fa-music:before {
  content: "\F001"; }

.fa-napster:before {
  content: "\F3D2"; }

.fa-narwhal:before {
  content: "\F6FE"; }

.fa-neos:before {
  content: "\F612"; }

.fa-network-wired:before {
  content: "\F6FF"; }

.fa-neuter:before {
  content: "\F22C"; }

.fa-newspaper:before {
  content: "\F1EA"; }

.fa-nimblr:before {
  content: "\F5A8"; }

.fa-nintendo-switch:before {
  content: "\F418"; }

.fa-node:before {
  content: "\F419"; }

.fa-node-js:before {
  content: "\F3D3"; }

.fa-not-equal:before {
  content: "\F53E"; }

.fa-notes-medical:before {
  content: "\F481"; }

.fa-npm:before {
  content: "\F3D4"; }

.fa-ns8:before {
  content: "\F3D5"; }

.fa-nutritionix:before {
  content: "\F3D6"; }

.fa-object-group:before {
  content: "\F247"; }

.fa-object-ungroup:before {
  content: "\F248"; }

.fa-octagon:before {
  content: "\F306"; }

.fa-odnoklassniki:before {
  content: "\F263"; }

.fa-odnoklassniki-square:before {
  content: "\F264"; }

.fa-oil-can:before {
  content: "\F613"; }

.fa-oil-temp:before {
  content: "\F614"; }

.fa-old-republic:before {
  content: "\F510"; }

.fa-om:before {
  content: "\F679"; }

.fa-omega:before {
  content: "\F67A"; }

.fa-opencart:before {
  content: "\F23D"; }

.fa-openid:before {
  content: "\F19B"; }

.fa-opera:before {
  content: "\F26A"; }

.fa-optin-monster:before {
  content: "\F23C"; }

.fa-osi:before {
  content: "\F41A"; }

.fa-otter:before {
  content: "\F700"; }

.fa-outdent:before {
  content: "\F03B"; }

.fa-page4:before {
  content: "\F3D7"; }

.fa-pagelines:before {
  content: "\F18C"; }

.fa-paint-brush:before {
  content: "\F1FC"; }

.fa-paint-brush-alt:before {
  content: "\F5A9"; }

.fa-paint-roller:before {
  content: "\F5AA"; }

.fa-palette:before {
  content: "\F53F"; }

.fa-palfed:before {
  content: "\F3D8"; }

.fa-pallet:before {
  content: "\F482"; }

.fa-pallet-alt:before {
  content: "\F483"; }

.fa-paper-plane:before {
  content: "\F1D8"; }

.fa-paperclip:before {
  content: "\F0C6"; }

.fa-parachute-box:before {
  content: "\F4CD"; }

.fa-paragraph:before {
  content: "\F1DD"; }

.fa-parking:before {
  content: "\F540"; }

.fa-parking-circle:before {
  content: "\F615"; }

.fa-parking-circle-slash:before {
  content: "\F616"; }

.fa-parking-slash:before {
  content: "\F617"; }

.fa-passport:before {
  content: "\F5AB"; }

.fa-pastafarianism:before {
  content: "\F67B"; }

.fa-paste:before {
  content: "\F0EA"; }

.fa-patreon:before {
  content: "\F3D9"; }

.fa-pause:before {
  content: "\F04C"; }

.fa-pause-circle:before {
  content: "\F28B"; }

.fa-paw:before {
  content: "\F1B0"; }

.fa-paw-alt:before {
  content: "\F701"; }

.fa-paw-claws:before {
  content: "\F702"; }

.fa-paypal:before {
  content: "\F1ED"; }

.fa-peace:before {
  content: "\F67C"; }

.fa-pegasus:before {
  content: "\F703"; }

.fa-pen:before {
  content: "\F304"; }

.fa-pen-alt:before {
  content: "\F305"; }

.fa-pen-fancy:before {
  content: "\F5AC"; }

.fa-pen-nib:before {
  content: "\F5AD"; }

.fa-pen-square:before {
  content: "\F14B"; }

.fa-pencil:before {
  content: "\F040"; }

.fa-pencil-alt:before {
  content: "\F303"; }

.fa-pencil-paintbrush:before {
  content: "\F618"; }

.fa-pencil-ruler:before {
  content: "\F5AE"; }

.fa-pennant:before {
  content: "\F456"; }

.fa-penny-arcade:before {
  content: "\F704"; }

.fa-people-carry:before {
  content: "\F4CE"; }

.fa-percent:before {
  content: "\F295"; }

.fa-percentage:before {
  content: "\F541"; }

.fa-periscope:before {
  content: "\F3DA"; }

.fa-person-booth:before {
  content: "\F756"; }

.fa-person-carry:before {
  content: "\F4CF"; }

.fa-person-dolly:before {
  content: "\F4D0"; }

.fa-person-dolly-empty:before {
  content: "\F4D1"; }

.fa-person-sign:before {
  content: "\F757"; }

.fa-phabricator:before {
  content: "\F3DB"; }

.fa-phoenix-framework:before {
  content: "\F3DC"; }

.fa-phoenix-squadron:before {
  content: "\F511"; }

.fa-phone:before {
  content: "\F095"; }

.fa-phone-office:before {
  content: "\F67D"; }

.fa-phone-plus:before {
  content: "\F4D2"; }

.fa-phone-slash:before {
  content: "\F3DD"; }

.fa-phone-square:before {
  content: "\F098"; }

.fa-phone-volume:before {
  content: "\F2A0"; }

.fa-php:before {
  content: "\F457"; }

.fa-pi:before {
  content: "\F67E"; }

.fa-pie:before {
  content: "\F705"; }

.fa-pied-piper:before {
  content: "\F2AE"; }

.fa-pied-piper-alt:before {
  content: "\F1A8"; }

.fa-pied-piper-hat:before {
  content: "\F4E5"; }

.fa-pied-piper-pp:before {
  content: "\F1A7"; }

.fa-pig:before {
  content: "\F706"; }

.fa-piggy-bank:before {
  content: "\F4D3"; }

.fa-pills:before {
  content: "\F484"; }

.fa-pinterest:before {
  content: "\F0D2"; }

.fa-pinterest-p:before {
  content: "\F231"; }

.fa-pinterest-square:before {
  content: "\F0D3"; }

.fa-place-of-worship:before {
  content: "\F67F"; }

.fa-plane:before {
  content: "\F072"; }

.fa-plane-alt:before {
  content: "\F3DE"; }

.fa-plane-arrival:before {
  content: "\F5AF"; }

.fa-plane-departure:before {
  content: "\F5B0"; }

.fa-play:before {
  content: "\F04B"; }

.fa-play-circle:before {
  content: "\F144"; }

.fa-playstation:before {
  content: "\F3DF"; }

.fa-plug:before {
  content: "\F1E6"; }

.fa-plus:before {
  content: "\F067"; }

.fa-plus-circle:before {
  content: "\F055"; }

.fa-plus-hexagon:before {
  content: "\F300"; }

.fa-plus-octagon:before {
  content: "\F301"; }

.fa-plus-square:before {
  content: "\F0FE"; }

.fa-podcast:before {
  content: "\F2CE"; }

.fa-podium:before {
  content: "\F680"; }

.fa-podium-star:before {
  content: "\F758"; }

.fa-poll:before {
  content: "\F681"; }

.fa-poll-h:before {
  content: "\F682"; }

.fa-poll-people:before {
  content: "\F759"; }

.fa-poo:before {
  content: "\F2FE"; }

.fa-poo-storm:before {
  content: "\F75A"; }

.fa-poop:before {
  content: "\F619"; }

.fa-portrait:before {
  content: "\F3E0"; }

.fa-pound-sign:before {
  content: "\F154"; }

.fa-power-off:before {
  content: "\F011"; }

.fa-pray:before {
  content: "\F683"; }

.fa-praying-hands:before {
  content: "\F684"; }

.fa-prescription:before {
  content: "\F5B1"; }

.fa-prescription-bottle:before {
  content: "\F485"; }

.fa-prescription-bottle-alt:before {
  content: "\F486"; }

.fa-presentation:before {
  content: "\F685"; }

.fa-print:before {
  content: "\F02F"; }

.fa-print-slash:before {
  content: "\F686"; }

.fa-procedures:before {
  content: "\F487"; }

.fa-product-hunt:before {
  content: "\F288"; }

.fa-project-diagram:before {
  content: "\F542"; }

.fa-pumpkin:before {
  content: "\F707"; }

.fa-pushed:before {
  content: "\F3E1"; }

.fa-puzzle-piece:before {
  content: "\F12E"; }

.fa-python:before {
  content: "\F3E2"; }

.fa-qq:before {
  content: "\F1D6"; }

.fa-qrcode:before {
  content: "\F029"; }

.fa-question:before {
  content: "\F128"; }

.fa-question-circle:before {
  content: "\F059"; }

.fa-question-square:before {
  content: "\F2FD"; }

.fa-quidditch:before {
  content: "\F458"; }

.fa-quinscape:before {
  content: "\F459"; }

.fa-quora:before {
  content: "\F2C4"; }

.fa-quote-left:before {
  content: "\F10D"; }

.fa-quote-right:before {
  content: "\F10E"; }

.fa-quran:before {
  content: "\F687"; }

.fa-r-project:before {
  content: "\F4F7"; }

.fa-rabbit:before {
  content: "\F708"; }

.fa-rabbit-fast:before {
  content: "\F709"; }

.fa-racquet:before {
  content: "\F45A"; }

.fa-rainbow:before {
  content: "\F75B"; }

.fa-raindrops:before {
  content: "\F75C"; }

.fa-ram:before {
  content: "\F70A"; }

.fa-ramp-loading:before {
  content: "\F4D4"; }

.fa-random:before {
  content: "\F074"; }

.fa-ravelry:before {
  content: "\F2D9"; }

.fa-react:before {
  content: "\F41B"; }

.fa-reacteurope:before {
  content: "\F75D"; }

.fa-readme:before {
  content: "\F4D5"; }

.fa-rebel:before {
  content: "\F1D0"; }

.fa-receipt:before {
  content: "\F543"; }

.fa-rectangle-landscape:before {
  content: "\F2FA"; }

.fa-rectangle-portrait:before {
  content: "\F2FB"; }

.fa-rectangle-wide:before {
  content: "\F2FC"; }

.fa-recycle:before {
  content: "\F1B8"; }

.fa-red-river:before {
  content: "\F3E3"; }

.fa-reddit:before {
  content: "\F1A1"; }

.fa-reddit-alien:before {
  content: "\F281"; }

.fa-reddit-square:before {
  content: "\F1A2"; }

.fa-redo:before {
  content: "\F01E"; }

.fa-redo-alt:before {
  content: "\F2F9"; }

.fa-registered:before {
  content: "\F25D"; }

.fa-renren:before {
  content: "\F18B"; }

.fa-repeat:before {
  content: "\F363"; }

.fa-repeat-1:before {
  content: "\F365"; }

.fa-repeat-1-alt:before {
  content: "\F366"; }

.fa-repeat-alt:before {
  content: "\F364"; }

.fa-reply:before {
  content: "\F3E5"; }

.fa-reply-all:before {
  content: "\F122"; }

.fa-replyd:before {
  content: "\F3E6"; }

.fa-republican:before {
  content: "\F75E"; }

.fa-researchgate:before {
  content: "\F4F8"; }

.fa-resolving:before {
  content: "\F3E7"; }

.fa-retweet:before {
  content: "\F079"; }

.fa-retweet-alt:before {
  content: "\F361"; }

.fa-rev:before {
  content: "\F5B2"; }

.fa-ribbon:before {
  content: "\F4D6"; }

.fa-ring:before {
  content: "\F70B"; }

.fa-road:before {
  content: "\F018"; }

.fa-robot:before {
  content: "\F544"; }

.fa-rocket:before {
  content: "\F135"; }

.fa-rocketchat:before {
  content: "\F3E8"; }

.fa-rockrms:before {
  content: "\F3E9"; }

.fa-route:before {
  content: "\F4D7"; }

.fa-route-highway:before {
  content: "\F61A"; }

.fa-route-interstate:before {
  content: "\F61B"; }

.fa-rss:before {
  content: "\F09E"; }

.fa-rss-square:before {
  content: "\F143"; }

.fa-ruble-sign:before {
  content: "\F158"; }

.fa-ruler:before {
  content: "\F545"; }

.fa-ruler-combined:before {
  content: "\F546"; }

.fa-ruler-horizontal:before {
  content: "\F547"; }

.fa-ruler-triangle:before {
  content: "\F61C"; }

.fa-ruler-vertical:before {
  content: "\F548"; }

.fa-running:before {
  content: "\F70C"; }

.fa-rupee-sign:before {
  content: "\F156"; }

.fa-sad-cry:before {
  content: "\F5B3"; }

.fa-sad-tear:before {
  content: "\F5B4"; }

.fa-safari:before {
  content: "\F267"; }

.fa-sass:before {
  content: "\F41E"; }

.fa-save:before {
  content: "\F0C7"; }

.fa-scalpel:before {
  content: "\F61D"; }

.fa-scalpel-path:before {
  content: "\F61E"; }

.fa-scanner:before {
  content: "\F488"; }

.fa-scanner-keyboard:before {
  content: "\F489"; }

.fa-scanner-touchscreen:before {
  content: "\F48A"; }

.fa-scarecrow:before {
  content: "\F70D"; }

.fa-schlix:before {
  content: "\F3EA"; }

.fa-school:before {
  content: "\F549"; }

.fa-screwdriver:before {
  content: "\F54A"; }

.fa-scribd:before {
  content: "\F28A"; }

.fa-scroll:before {
  content: "\F70E"; }

.fa-scroll-old:before {
  content: "\F70F"; }

.fa-scrubber:before {
  content: "\F2F8"; }

.fa-scythe:before {
  content: "\F710"; }

.fa-search:before {
  content: "\F002"; }

.fa-search-dollar:before {
  content: "\F688"; }

.fa-search-location:before {
  content: "\F689"; }

.fa-search-minus:before {
  content: "\F010"; }

.fa-search-plus:before {
  content: "\F00E"; }

.fa-searchengin:before {
  content: "\F3EB"; }

.fa-seedling:before {
  content: "\F4D8"; }

.fa-sellcast:before {
  content: "\F2DA"; }

.fa-sellsy:before {
  content: "\F213"; }

.fa-server:before {
  content: "\F233"; }

.fa-servicestack:before {
  content: "\F3EC"; }

.fa-shapes:before {
  content: "\F61F"; }

.fa-share:before {
  content: "\F064"; }

.fa-share-all:before {
  content: "\F367"; }

.fa-share-alt:before {
  content: "\F1E0"; }

.fa-share-alt-square:before {
  content: "\F1E1"; }

.fa-share-square:before {
  content: "\F14D"; }

.fa-sheep:before {
  content: "\F711"; }

.fa-shekel-sign:before {
  content: "\F20B"; }

.fa-shield:before {
  content: "\F132"; }

.fa-shield-alt:before {
  content: "\F3ED"; }

.fa-shield-check:before {
  content: "\F2F7"; }

.fa-shield-cross:before {
  content: "\F712"; }

.fa-ship:before {
  content: "\F21A"; }

.fa-shipping-fast:before {
  content: "\F48B"; }

.fa-shipping-timed:before {
  content: "\F48C"; }

.fa-shirtsinbulk:before {
  content: "\F214"; }

.fa-shoe-prints:before {
  content: "\F54B"; }

.fa-shopping-bag:before {
  content: "\F290"; }

.fa-shopping-basket:before {
  content: "\F291"; }

.fa-shopping-cart:before {
  content: "\F07A"; }

.fa-shopware:before {
  content: "\F5B5"; }

.fa-shovel:before {
  content: "\F713"; }

.fa-shower:before {
  content: "\F2CC"; }

.fa-shredder:before {
  content: "\F68A"; }

.fa-shuttle-van:before {
  content: "\F5B6"; }

.fa-shuttlecock:before {
  content: "\F45B"; }

.fa-sigma:before {
  content: "\F68B"; }

.fa-sign:before {
  content: "\F4D9"; }

.fa-sign-in:before {
  content: "\F090"; }

.fa-sign-in-alt:before {
  content: "\F2F6"; }

.fa-sign-language:before {
  content: "\F2A7"; }

.fa-sign-out:before {
  content: "\F08B"; }

.fa-sign-out-alt:before {
  content: "\F2F5"; }

.fa-signal:before {
  content: "\F012"; }

.fa-signal-1:before {
  content: "\F68C"; }

.fa-signal-2:before {
  content: "\F68D"; }

.fa-signal-3:before {
  content: "\F68E"; }

.fa-signal-4:before {
  content: "\F68F"; }

.fa-signal-alt:before {
  content: "\F690"; }

.fa-signal-alt-1:before {
  content: "\F691"; }

.fa-signal-alt-2:before {
  content: "\F692"; }

.fa-signal-alt-3:before {
  content: "\F693"; }

.fa-signal-alt-slash:before {
  content: "\F694"; }

.fa-signal-slash:before {
  content: "\F695"; }

.fa-signature:before {
  content: "\F5B7"; }

.fa-simplybuilt:before {
  content: "\F215"; }

.fa-sistrix:before {
  content: "\F3EE"; }

.fa-sitemap:before {
  content: "\F0E8"; }

.fa-sith:before {
  content: "\F512"; }

.fa-skeleton:before {
  content: "\F620"; }

.fa-skull:before {
  content: "\F54C"; }

.fa-skull-crossbones:before {
  content: "\F714"; }

.fa-skyatlas:before {
  content: "\F216"; }

.fa-skype:before {
  content: "\F17E"; }

.fa-slack:before {
  content: "\F198"; }

.fa-slack-hash:before {
  content: "\F3EF"; }

.fa-slash:before {
  content: "\F715"; }

.fa-sliders-h:before {
  content: "\F1DE"; }

.fa-sliders-h-square:before {
  content: "\F3F0"; }

.fa-sliders-v:before {
  content: "\F3F1"; }

.fa-sliders-v-square:before {
  content: "\F3F2"; }

.fa-slideshare:before {
  content: "\F1E7"; }

.fa-smile:before {
  content: "\F118"; }

.fa-smile-beam:before {
  content: "\F5B8"; }

.fa-smile-plus:before {
  content: "\F5B9"; }

.fa-smile-wink:before {
  content: "\F4DA"; }

.fa-smog:before {
  content: "\F75F"; }

.fa-smoke:before {
  content: "\F760"; }

.fa-smoking:before {
  content: "\F48D"; }

.fa-smoking-ban:before {
  content: "\F54D"; }

.fa-snake:before {
  content: "\F716"; }

.fa-snapchat:before {
  content: "\F2AB"; }

.fa-snapchat-ghost:before {
  content: "\F2AC"; }

.fa-snapchat-square:before {
  content: "\F2AD"; }

.fa-snow-blowing:before {
  content: "\F761"; }

.fa-snowflake:before {
  content: "\F2DC"; }

.fa-socks:before {
  content: "\F696"; }

.fa-solar-panel:before {
  content: "\F5BA"; }

.fa-sort:before {
  content: "\F0DC"; }

.fa-sort-alpha-down:before {
  content: "\F15D"; }

.fa-sort-alpha-up:before {
  content: "\F15E"; }

.fa-sort-amount-down:before {
  content: "\F160"; }

.fa-sort-amount-up:before {
  content: "\F161"; }

.fa-sort-down:before {
  content: "\F0DD"; }

.fa-sort-numeric-down:before {
  content: "\F162"; }

.fa-sort-numeric-up:before {
  content: "\F163"; }

.fa-sort-up:before {
  content: "\F0DE"; }

.fa-soundcloud:before {
  content: "\F1BE"; }

.fa-spa:before {
  content: "\F5BB"; }

.fa-space-shuttle:before {
  content: "\F197"; }

.fa-spade:before {
  content: "\F2F4"; }

.fa-speakap:before {
  content: "\F3F3"; }

.fa-spider:before {
  content: "\F717"; }

.fa-spider-black-widow:before {
  content: "\F718"; }

.fa-spider-web:before {
  content: "\F719"; }

.fa-spinner:before {
  content: "\F110"; }

.fa-spinner-third:before {
  content: "\F3F4"; }

.fa-splotch:before {
  content: "\F5BC"; }

.fa-spotify:before {
  content: "\F1BC"; }

.fa-spray-can:before {
  content: "\F5BD"; }

.fa-square:before {
  content: "\F0C8"; }

.fa-square-full:before {
  content: "\F45C"; }

.fa-square-root:before {
  content: "\F697"; }

.fa-square-root-alt:before {
  content: "\F698"; }

.fa-squarespace:before {
  content: "\F5BE"; }

.fa-squirrel:before {
  content: "\F71A"; }

.fa-stack-exchange:before {
  content: "\F18D"; }

.fa-stack-overflow:before {
  content: "\F16C"; }

.fa-staff:before {
  content: "\F71B"; }

.fa-stamp:before {
  content: "\F5BF"; }

.fa-star:before {
  content: "\F005"; }

.fa-star-and-crescent:before {
  content: "\F699"; }

.fa-star-exclamation:before {
  content: "\F2F3"; }

.fa-star-half:before {
  content: "\F089"; }

.fa-star-half-alt:before {
  content: "\F5C0"; }

.fa-star-of-david:before {
  content: "\F69A"; }

.fa-star-of-life:before {
  content: "\F621"; }

.fa-stars:before {
  content: "\F762"; }

.fa-staylinked:before {
  content: "\F3F5"; }

.fa-steam:before {
  content: "\F1B6"; }

.fa-steam-square:before {
  content: "\F1B7"; }

.fa-steam-symbol:before {
  content: "\F3F6"; }

.fa-steering-wheel:before {
  content: "\F622"; }

.fa-step-backward:before {
  content: "\F048"; }

.fa-step-forward:before {
  content: "\F051"; }

.fa-stethoscope:before {
  content: "\F0F1"; }

.fa-sticker-mule:before {
  content: "\F3F7"; }

.fa-sticky-note:before {
  content: "\F249"; }

.fa-stomach:before {
  content: "\F623"; }

.fa-stop:before {
  content: "\F04D"; }

.fa-stop-circle:before {
  content: "\F28D"; }

.fa-stopwatch:before {
  content: "\F2F2"; }

.fa-store:before {
  content: "\F54E"; }

.fa-store-alt:before {
  content: "\F54F"; }

.fa-strava:before {
  content: "\F428"; }

.fa-stream:before {
  content: "\F550"; }

.fa-street-view:before {
  content: "\F21D"; }

.fa-strikethrough:before {
  content: "\F0CC"; }

.fa-stripe:before {
  content: "\F429"; }

.fa-stripe-s:before {
  content: "\F42A"; }

.fa-stroopwafel:before {
  content: "\F551"; }

.fa-studiovinari:before {
  content: "\F3F8"; }

.fa-stumbleupon:before {
  content: "\F1A4"; }

.fa-stumbleupon-circle:before {
  content: "\F1A3"; }

.fa-subscript:before {
  content: "\F12C"; }

.fa-subway:before {
  content: "\F239"; }

.fa-suitcase:before {
  content: "\F0F2"; }

.fa-suitcase-rolling:before {
  content: "\F5C1"; }

.fa-sun:before {
  content: "\F185"; }

.fa-sun-cloud:before {
  content: "\F763"; }

.fa-sun-dust:before {
  content: "\F764"; }

.fa-sun-haze:before {
  content: "\F765"; }

.fa-sunrise:before {
  content: "\F766"; }

.fa-sunset:before {
  content: "\F767"; }

.fa-superpowers:before {
  content: "\F2DD"; }

.fa-superscript:before {
  content: "\F12B"; }

.fa-supple:before {
  content: "\F3F9"; }

.fa-surprise:before {
  content: "\F5C2"; }

.fa-swatchbook:before {
  content: "\F5C3"; }

.fa-swimmer:before {
  content: "\F5C4"; }

.fa-swimming-pool:before {
  content: "\F5C5"; }

.fa-sword:before {
  content: "\F71C"; }

.fa-swords:before {
  content: "\F71D"; }

.fa-synagogue:before {
  content: "\F69B"; }

.fa-sync:before {
  content: "\F021"; }

.fa-sync-alt:before {
  content: "\F2F1"; }

.fa-syringe:before {
  content: "\F48E"; }

.fa-table:before {
  content: "\F0CE"; }

.fa-table-tennis:before {
  content: "\F45D"; }

.fa-tablet:before {
  content: "\F10A"; }

.fa-tablet-alt:before {
  content: "\F3FA"; }

.fa-tablet-android:before {
  content: "\F3FB"; }

.fa-tablet-android-alt:before {
  content: "\F3FC"; }

.fa-tablet-rugged:before {
  content: "\F48F"; }

.fa-tablets:before {
  content: "\F490"; }

.fa-tachometer:before {
  content: "\F0E4"; }

.fa-tachometer-alt:before {
  content: "\F3FD"; }

.fa-tachometer-alt-average:before {
  content: "\F624"; }

.fa-tachometer-alt-fast:before {
  content: "\F625"; }

.fa-tachometer-alt-fastest:before {
  content: "\F626"; }

.fa-tachometer-alt-slow:before {
  content: "\F627"; }

.fa-tachometer-alt-slowest:before {
  content: "\F628"; }

.fa-tachometer-average:before {
  content: "\F629"; }

.fa-tachometer-fast:before {
  content: "\F62A"; }

.fa-tachometer-fastest:before {
  content: "\F62B"; }

.fa-tachometer-slow:before {
  content: "\F62C"; }

.fa-tachometer-slowest:before {
  content: "\F62D"; }

.fa-tag:before {
  content: "\F02B"; }

.fa-tags:before {
  content: "\F02C"; }

.fa-tally:before {
  content: "\F69C"; }

.fa-tape:before {
  content: "\F4DB"; }

.fa-tasks:before {
  content: "\F0AE"; }

.fa-taxi:before {
  content: "\F1BA"; }

.fa-teamspeak:before {
  content: "\F4F9"; }

.fa-teeth:before {
  content: "\F62E"; }

.fa-teeth-open:before {
  content: "\F62F"; }

.fa-telegram:before {
  content: "\F2C6"; }

.fa-telegram-plane:before {
  content: "\F3FE"; }

.fa-temperature-frigid:before {
  content: "\F768"; }

.fa-temperature-high:before {
  content: "\F769"; }

.fa-temperature-hot:before {
  content: "\F76A"; }

.fa-temperature-low:before {
  content: "\F76B"; }

.fa-tencent-weibo:before {
  content: "\F1D5"; }

.fa-tennis-ball:before {
  content: "\F45E"; }

.fa-terminal:before {
  content: "\F120"; }

.fa-text-height:before {
  content: "\F034"; }

.fa-text-width:before {
  content: "\F035"; }

.fa-th:before {
  content: "\F00A"; }

.fa-th-large:before {
  content: "\F009"; }

.fa-th-list:before {
  content: "\F00B"; }

.fa-the-red-yeti:before {
  content: "\F69D"; }

.fa-theater-masks:before {
  content: "\F630"; }

.fa-themeco:before {
  content: "\F5C6"; }

.fa-themeisle:before {
  content: "\F2B2"; }

.fa-thermometer:before {
  content: "\F491"; }

.fa-thermometer-empty:before {
  content: "\F2CB"; }

.fa-thermometer-full:before {
  content: "\F2C7"; }

.fa-thermometer-half:before {
  content: "\F2C9"; }

.fa-thermometer-quarter:before {
  content: "\F2CA"; }

.fa-thermometer-three-quarters:before {
  content: "\F2C8"; }

.fa-theta:before {
  content: "\F69E"; }

.fa-think-peaks:before {
  content: "\F731"; }

.fa-thumbs-down:before {
  content: "\F165"; }

.fa-thumbs-up:before {
  content: "\F164"; }

.fa-thumbtack:before {
  content: "\F08D"; }

.fa-thunderstorm:before {
  content: "\F76C"; }

.fa-thunderstorm-moon:before {
  content: "\F76D"; }

.fa-thunderstorm-sun:before {
  content: "\F76E"; }

.fa-ticket:before {
  content: "\F145"; }

.fa-ticket-alt:before {
  content: "\F3FF"; }

.fa-tilde:before {
  content: "\F69F"; }

.fa-times:before {
  content: "\F00D"; }

.fa-times-circle:before {
  content: "\F057"; }

.fa-times-hexagon:before {
  content: "\F2EE"; }

.fa-times-octagon:before {
  content: "\F2F0"; }

.fa-times-square:before {
  content: "\F2D3"; }

.fa-tint:before {
  content: "\F043"; }

.fa-tint-slash:before {
  content: "\F5C7"; }

.fa-tire:before {
  content: "\F631"; }

.fa-tire-flat:before {
  content: "\F632"; }

.fa-tire-pressure-warning:before {
  content: "\F633"; }

.fa-tire-rugged:before {
  content: "\F634"; }

.fa-tired:before {
  content: "\F5C8"; }

.fa-toggle-off:before {
  content: "\F204"; }

.fa-toggle-on:before {
  content: "\F205"; }

.fa-toilet-paper:before {
  content: "\F71E"; }

.fa-toilet-paper-alt:before {
  content: "\F71F"; }

.fa-tombstone:before {
  content: "\F720"; }

.fa-tombstone-alt:before {
  content: "\F721"; }

.fa-toolbox:before {
  content: "\F552"; }

.fa-tooth:before {
  content: "\F5C9"; }

.fa-toothbrush:before {
  content: "\F635"; }

.fa-torah:before {
  content: "\F6A0"; }

.fa-torii-gate:before {
  content: "\F6A1"; }

.fa-tornado:before {
  content: "\F76F"; }

.fa-tractor:before {
  content: "\F722"; }

.fa-trade-federation:before {
  content: "\F513"; }

.fa-trademark:before {
  content: "\F25C"; }

.fa-traffic-cone:before {
  content: "\F636"; }

.fa-traffic-light:before {
  content: "\F637"; }

.fa-traffic-light-go:before {
  content: "\F638"; }

.fa-traffic-light-slow:before {
  content: "\F639"; }

.fa-traffic-light-stop:before {
  content: "\F63A"; }

.fa-train:before {
  content: "\F238"; }

.fa-transgender:before {
  content: "\F224"; }

.fa-transgender-alt:before {
  content: "\F225"; }

.fa-trash:before {
  content: "\F1F8"; }

.fa-trash-alt:before {
  content: "\F2ED"; }

.fa-treasure-chest:before {
  content: "\F723"; }

.fa-tree:before {
  content: "\F1BB"; }

.fa-tree-alt:before {
  content: "\F400"; }

.fa-trees:before {
  content: "\F724"; }

.fa-trello:before {
  content: "\F181"; }

.fa-triangle:before {
  content: "\F2EC"; }

.fa-tripadvisor:before {
  content: "\F262"; }

.fa-trophy:before {
  content: "\F091"; }

.fa-trophy-alt:before {
  content: "\F2EB"; }

.fa-truck:before {
  content: "\F0D1"; }

.fa-truck-container:before {
  content: "\F4DC"; }

.fa-truck-couch:before {
  content: "\F4DD"; }

.fa-truck-loading:before {
  content: "\F4DE"; }

.fa-truck-monster:before {
  content: "\F63B"; }

.fa-truck-moving:before {
  content: "\F4DF"; }

.fa-truck-pickup:before {
  content: "\F63C"; }

.fa-truck-ramp:before {
  content: "\F4E0"; }

.fa-tshirt:before {
  content: "\F553"; }

.fa-tty:before {
  content: "\F1E4"; }

.fa-tumblr:before {
  content: "\F173"; }

.fa-tumblr-square:before {
  content: "\F174"; }

.fa-turkey:before {
  content: "\F725"; }

.fa-turtle:before {
  content: "\F726"; }

.fa-tv:before {
  content: "\F26C"; }

.fa-tv-retro:before {
  content: "\F401"; }

.fa-twitch:before {
  content: "\F1E8"; }

.fa-twitter:before {
  content: "\F099"; }

.fa-twitter-square:before {
  content: "\F081"; }

.fa-typo3:before {
  content: "\F42B"; }

.fa-uber:before {
  content: "\F402"; }

.fa-uikit:before {
  content: "\F403"; }

.fa-umbrella:before {
  content: "\F0E9"; }

.fa-umbrella-beach:before {
  content: "\F5CA"; }

.fa-underline:before {
  content: "\F0CD"; }

.fa-undo:before {
  content: "\F0E2"; }

.fa-undo-alt:before {
  content: "\F2EA"; }

.fa-unicorn:before {
  content: "\F727"; }

.fa-union:before {
  content: "\F6A2"; }

.fa-uniregistry:before {
  content: "\F404"; }

.fa-universal-access:before {
  content: "\F29A"; }

.fa-university:before {
  content: "\F19C"; }

.fa-unlink:before {
  content: "\F127"; }

.fa-unlock:before {
  content: "\F09C"; }

.fa-unlock-alt:before {
  content: "\F13E"; }

.fa-untappd:before {
  content: "\F405"; }

.fa-upload:before {
  content: "\F093"; }

.fa-usb:before {
  content: "\F287"; }

.fa-usd-circle:before {
  content: "\F2E8"; }

.fa-usd-square:before {
  content: "\F2E9"; }

.fa-user:before {
  content: "\F007"; }

.fa-user-alt:before {
  content: "\F406"; }

.fa-user-alt-slash:before {
  content: "\F4FA"; }

.fa-user-astronaut:before {
  content: "\F4FB"; }

.fa-user-chart:before {
  content: "\F6A3"; }

.fa-user-check:before {
  content: "\F4FC"; }

.fa-user-circle:before {
  content: "\F2BD"; }

.fa-user-clock:before {
  content: "\F4FD"; }

.fa-user-cog:before {
  content: "\F4FE"; }

.fa-user-crown:before {
  content: "\F6A4"; }

.fa-user-edit:before {
  content: "\F4FF"; }

.fa-user-friends:before {
  content: "\F500"; }

.fa-user-graduate:before {
  content: "\F501"; }

.fa-user-injured:before {
  content: "\F728"; }

.fa-user-lock:before {
  content: "\F502"; }

.fa-user-md:before {
  content: "\F0F0"; }

.fa-user-minus:before {
  content: "\F503"; }

.fa-user-ninja:before {
  content: "\F504"; }

.fa-user-plus:before {
  content: "\F234"; }

.fa-user-secret:before {
  content: "\F21B"; }

.fa-user-shield:before {
  content: "\F505"; }

.fa-user-slash:before {
  content: "\F506"; }

.fa-user-tag:before {
  content: "\F507"; }

.fa-user-tie:before {
  content: "\F508"; }

.fa-user-times:before {
  content: "\F235"; }

.fa-users:before {
  content: "\F0C0"; }

.fa-users-class:before {
  content: "\F63D"; }

.fa-users-cog:before {
  content: "\F509"; }

.fa-users-crown:before {
  content: "\F6A5"; }

.fa-ussunnah:before {
  content: "\F407"; }

.fa-utensil-fork:before {
  content: "\F2E3"; }

.fa-utensil-knife:before {
  content: "\F2E4"; }

.fa-utensil-spoon:before {
  content: "\F2E5"; }

.fa-utensils:before {
  content: "\F2E7"; }

.fa-utensils-alt:before {
  content: "\F2E6"; }

.fa-vaadin:before {
  content: "\F408"; }

.fa-value-absolute:before {
  content: "\F6A6"; }

.fa-vector-square:before {
  content: "\F5CB"; }

.fa-venus:before {
  content: "\F221"; }

.fa-venus-double:before {
  content: "\F226"; }

.fa-venus-mars:before {
  content: "\F228"; }

.fa-viacoin:before {
  content: "\F237"; }

.fa-viadeo:before {
  content: "\F2A9"; }

.fa-viadeo-square:before {
  content: "\F2AA"; }

.fa-vial:before {
  content: "\F492"; }

.fa-vials:before {
  content: "\F493"; }

.fa-viber:before {
  content: "\F409"; }

.fa-video:before {
  content: "\F03D"; }

.fa-video-plus:before {
  content: "\F4E1"; }

.fa-video-slash:before {
  content: "\F4E2"; }

.fa-vihara:before {
  content: "\F6A7"; }

.fa-vimeo:before {
  content: "\F40A"; }

.fa-vimeo-square:before {
  content: "\F194"; }

.fa-vimeo-v:before {
  content: "\F27D"; }

.fa-vine:before {
  content: "\F1CA"; }

.fa-vk:before {
  content: "\F189"; }

.fa-vnv:before {
  content: "\F40B"; }

.fa-volcano:before {
  content: "\F770"; }

.fa-volleyball-ball:before {
  content: "\F45F"; }

.fa-volume:before {
  content: "\F6A8"; }

.fa-volume-down:before {
  content: "\F027"; }

.fa-volume-mute:before {
  content: "\F6A9"; }

.fa-volume-off:before {
  content: "\F026"; }

.fa-volume-slash:before {
  content: "\F2E2"; }

.fa-volume-up:before {
  content: "\F028"; }

.fa-vote-nay:before {
  content: "\F771"; }

.fa-vote-yea:before {
  content: "\F772"; }

.fa-vr-cardboard:before {
  content: "\F729"; }

.fa-vuejs:before {
  content: "\F41F"; }

.fa-walking:before {
  content: "\F554"; }

.fa-wallet:before {
  content: "\F555"; }

.fa-wand:before {
  content: "\F72A"; }

.fa-wand-magic:before {
  content: "\F72B"; }

.fa-warehouse:before {
  content: "\F494"; }

.fa-warehouse-alt:before {
  content: "\F495"; }

.fa-watch:before {
  content: "\F2E1"; }

.fa-watch-fitness:before {
  content: "\F63E"; }

.fa-water:before {
  content: "\F773"; }

.fa-water-lower:before {
  content: "\F774"; }

.fa-water-rise:before {
  content: "\F775"; }

.fa-weebly:before {
  content: "\F5CC"; }

.fa-weibo:before {
  content: "\F18A"; }

.fa-weight:before {
  content: "\F496"; }

.fa-weight-hanging:before {
  content: "\F5CD"; }

.fa-weixin:before {
  content: "\F1D7"; }

.fa-whale:before {
  content: "\F72C"; }

.fa-whatsapp:before {
  content: "\F232"; }

.fa-whatsapp-square:before {
  content: "\F40C"; }

.fa-wheat:before {
  content: "\F72D"; }

.fa-wheelchair:before {
  content: "\F193"; }

.fa-whistle:before {
  content: "\F460"; }

.fa-whmcs:before {
  content: "\F40D"; }

.fa-wifi:before {
  content: "\F1EB"; }

.fa-wifi-1:before {
  content: "\F6AA"; }

.fa-wifi-2:before {
  content: "\F6AB"; }

.fa-wifi-slash:before {
  content: "\F6AC"; }

.fa-wikipedia-w:before {
  content: "\F266"; }

.fa-wind:before {
  content: "\F72E"; }

.fa-wind-warning:before {
  content: "\F776"; }

.fa-window:before {
  content: "\F40E"; }

.fa-window-alt:before {
  content: "\F40F"; }

.fa-window-close:before {
  content: "\F410"; }

.fa-window-maximize:before {
  content: "\F2D0"; }

.fa-window-minimize:before {
  content: "\F2D1"; }

.fa-window-restore:before {
  content: "\F2D2"; }

.fa-windows:before {
  content: "\F17A"; }

.fa-windsock:before {
  content: "\F777"; }

.fa-wine-bottle:before {
  content: "\F72F"; }

.fa-wine-glass:before {
  content: "\F4E3"; }

.fa-wine-glass-alt:before {
  content: "\F5CE"; }

.fa-wix:before {
  content: "\F5CF"; }

.fa-wizards-of-the-coast:before {
  content: "\F730"; }

.fa-wolf-pack-battalion:before {
  content: "\F514"; }

.fa-won-sign:before {
  content: "\F159"; }

.fa-wordpress:before {
  content: "\F19A"; }

.fa-wordpress-simple:before {
  content: "\F411"; }

.fa-wpbeginner:before {
  content: "\F297"; }

.fa-wpexplorer:before {
  content: "\F2DE"; }

.fa-wpforms:before {
  content: "\F298"; }

.fa-wpressr:before {
  content: "\F3E4"; }

.fa-wrench:before {
  content: "\F0AD"; }

.fa-x-ray:before {
  content: "\F497"; }

.fa-xbox:before {
  content: "\F412"; }

.fa-xing:before {
  content: "\F168"; }

.fa-xing-square:before {
  content: "\F169"; }

.fa-y-combinator:before {
  content: "\F23B"; }

.fa-yahoo:before {
  content: "\F19E"; }

.fa-yandex:before {
  content: "\F413"; }

.fa-yandex-international:before {
  content: "\F414"; }

.fa-yelp:before {
  content: "\F1E9"; }

.fa-yen-sign:before {
  content: "\F157"; }

.fa-yin-yang:before {
  content: "\F6AD"; }

.fa-yoast:before {
  content: "\F2B1"; }

.fa-youtube:before {
  content: "\F167"; }

.fa-youtube-square:before {
  content: "\F431"; }

.fa-zhihu:before {
  content: "\F63F"; }

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  src: url(/build/fonts/fa-brands-400.c3c46766.eot);
  src: url(/build/fonts/fa-brands-400.c3c46766.eot?#iefix) format("embedded-opentype"), url(/build/fonts/fa-brands-400.0896516d.woff2) format("woff2"), url(/build/fonts/fa-brands-400.f6b98e39.woff) format("woff"), url(/build/fonts/fa-brands-400.c2ec6f2c.ttf) format("truetype"), url(/build/images/fa-brands-400.9c9373d9.svg#fontawesome) format("svg"); }

.fab {
  font-family: 'Font Awesome 5 Brands'; }
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  src: url(/build/fonts/fa-light-300.7c2d0adc.eot);
  src: url(/build/fonts/fa-light-300.7c2d0adc.eot?#iefix) format("embedded-opentype"), url(/build/fonts/fa-light-300.b076f7cc.woff2) format("woff2"), url(/build/fonts/fa-light-300.a3702ff5.woff) format("woff"), url(/build/fonts/fa-light-300.9b8974e9.ttf) format("truetype"), url(/build/images/fa-light-300.29fbe84e.svg#fontawesome) format("svg"); }

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300; }
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  src: url(/build/fonts/fa-regular-400.cc440dd7.eot);
  src: url(/build/fonts/fa-regular-400.cc440dd7.eot?#iefix) format("embedded-opentype"), url(/build/fonts/fa-regular-400.8b13141d.woff2) format("woff2"), url(/build/fonts/fa-regular-400.5b1cd2e2.woff) format("woff"), url(/build/fonts/fa-regular-400.ffbbfe0d.ttf) format("truetype"), url(/build/images/fa-regular-400.7f2e0f11.svg#fontawesome) format("svg"); }

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400; }
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  src: url(/build/fonts/fa-solid-900.1dac132e.eot);
  src: url(/build/fonts/fa-solid-900.1dac132e.eot?#iefix) format("embedded-opentype"), url(/build/fonts/fa-solid-900.a265bbc2.woff2) format("woff2"), url(/build/fonts/fa-solid-900.c81f3983.woff) format("woff"), url(/build/fonts/fa-solid-900.08b3d092.ttf) format("truetype"), url(/build/images/fa-solid-900.80b79370.svg#fontawesome) format("svg"); }

.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900; }

/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014   \A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated
  .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated
  .custom-select:valid ~ .valid-feedback,
.was-validated
  .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip,
.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip,
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::after,
.custom-file-input.is-valid ~ .custom-file-label::after {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip,
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated
  .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated
  .custom-select:invalid ~ .invalid-feedback,
.was-validated
  .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip,
.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip,
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::after,
.custom-file-input.is-invalid ~ .custom-file-label::after {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip,
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover,
.btn:focus {
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #dee2e6;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #80bdff;
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
       appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }

  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:first-child .card-img-top,
  .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:first-child .card-img-bottom,
  .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:last-child .card-img-top,
  .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:last-child .card-img-bottom,
  .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }

  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }

  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }

  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:hover,
.badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:hover,
.badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:hover,
.badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:hover,
.badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:hover,
.badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:hover,
.badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-light[href]:hover,
.badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:hover,
.badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:hover,
.list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
          transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }

  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top .arrow,
.bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right .arrow,
.bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom .arrow,
.bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left .arrow,
.bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-next,
  .active.carousel-item-right {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-prev,
  .active.carousel-item-left {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #0062cc !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #1d2124 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

@page {
    size: a3;
}

  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

#cssmenu,
#cssmenu ul,
#cssmenu ul li,
#cssmenu ul li a,
#cssmenu #menu-button {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  line-height: 1;
  display: block;
  position: relative;
  box-sizing: border-box;
  z-index: 2000;
}

#cssmenu:after,
#cssmenu > ul:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

#cssmenu #menu-button {
  display: block;
  float: left;
  z-index: 2001;
}

#cssmenu #menu-button img {
  height: 84px;
  padding: 10px;
  padding-left: 15px;
}

#cssmenu {
  font-family: Montserrat, sans-serif;
}

#cssmenu > ul > li {
  float: left;
}

#cssmenu.align-center > ul {
  font-size: 0;
  text-align: center;
}

#cssmenu.align-center > ul > li {
  display: inline-block;
  float: none;
}

#cssmenu > ul li a {
  height: 80px;
  line-height: 80px;
}

#cssmenu.align-center ul ul {
  text-align: left;
}

#cssmenu.align-right > ul {
  float: right;
  height: 80px;
  align-items: center;
}

#cssmenu > ul > li > a {
  padding-right: 30px;
  font-size: 14px;
  letter-spacing: 1px;
  text-decoration: none;
  color: #dddddd;
  font-weight: 700;
  text-transform: uppercase;
}

#cssmenu > ul > li:hover > a {
  color: #ffcb04;
}

#cssmenu > ul > li.has-sub > a {
  padding-right: 30px;
}

#cssmenu > ul > li.has-sub > a:after {
  position: absolute;
  top: 28px;
  right: 11px;
  width: 8px;
  height: 2px;
  display: block;
  background: #dddddd;
  content: '';
}

#cssmenu > ul > li.has-sub > a:before {
  position: absolute;
  top: 25px;
  right: 14px;
  display: block;
  width: 2px;
  height: 8px;
  background: #dddddd;
  content: '';
  transition: all .25s ease;
}

#cssmenu > ul > li.has-sub:hover > a:before {
  top: 28px;
  height: 0;
}

#cssmenu ul ul {
  position: absolute;
  left: -9999px;
}

#cssmenu.align-right ul ul {
  text-align: left;
}

#cssmenu ul ul li {
  height: 0;
  transition: all .25s ease;
}

#cssmenu li:hover > ul {
  left: auto;
}

#cssmenu.align-right li:hover > ul {
  left: auto;
  right: 0;
}

#cssmenu li:hover > ul > li {
  height: 35px;
}

#cssmenu ul ul ul {
  margin-left: 100%;
  top: 0;
}

#cssmenu.align-right ul ul ul {
  margin-left: 0;
  margin-right: 100%;
}

#cssmenu ul ul li a {
  border-bottom: 1px solid rgba(150, 150, 150, 0.15);
  padding: 11px 15px;
  width: 170px;
  font-size: 12px;
  text-decoration: none;
  color: #dddddd;
  font-weight: 400;
  background: #333333;
  white-space: nowrap;
}

#cssmenu ul ul li:last-child > a,
#cssmenu ul ul li.last-item > a {
  border-bottom: 0;
}

#cssmenu ul ul li:hover > a,
#cssmenu ul ul li a:hover {
  color: #ffcb04;
}

#cssmenu ul ul li.has-sub > a:after {
  position: absolute;
  top: 16px;
  right: 11px;
  width: 8px;
  height: 2px;
  display: block;
  background: #dddddd;
  content: '';
}

#cssmenu.align-right ul ul li.has-sub > a:after {
  right: auto;
  right: 11px;
}

#cssmenu ul ul li.has-sub > a:before {
  position: absolute;
  top: 13px;
  right: 14px;
  display: block;
  width: 2px;
  height: 8px;
  background: #dddddd;
  content: '';
  transition: all .25s ease;
}

#cssmenu.align-right ul ul li.has-sub > a:before {
  right: auto;
  right: 14px;
}

#cssmenu ul ul > li.has-sub:hover > a:before {
  top: 17px;
  height: 0;
}

@media screen and (max-width: 900px) {
  #cssmenu {
    width: 100%;
  }

  #cssmenu ul {
    width: 100%;
    display: none;
    background-color: #333333;
  }

  #cssmenu.align-center > ul {
    text-align: left;
  }

  #cssmenu ul li {
    width: 100%;
    border-top: 1px solid rgba(120, 120, 120, 0.2);
  }

  #cssmenu ul ul li,
  #cssmenu li:hover > ul > li {
    height: auto;
  }

  #cssmenu ul li a,
  #cssmenu ul ul li a {
    width: 100%;
    border-bottom: 0;
  }

  #cssmenu > ul > li {
    float: none;
  }

  #cssmenu ul ul li a {
    padding-left: 25px;
  }

  #cssmenu ul ul ul li a {
    padding-left: 35px;
  }

  #cssmenu ul ul li a {
    color: #dddddd;
    background: none;
  }

  #cssmenu ul ul li:hover > a,
  #cssmenu ul ul li.active > a {
    color: #ffcb04;
  }

  #cssmenu ul ul,
  #cssmenu ul ul ul,
  #cssmenu.align-right ul ul {
    position: relative;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: left;
  }

  #cssmenu > ul > li.has-sub > a:after,
  #cssmenu > ul > li.has-sub > a:before,
  #cssmenu ul ul > li.has-sub > a:after,
  #cssmenu ul ul > li.has-sub > a:before {
    display: none;
  }

  #cssmenu #menu-button {
    display: block;
    float: none;
  }

  #cssmenu #menu-button img {
    height: 50px;
    padding: 5px;
  }

  #cssmenu.align-right > ul {
    height: auto;
  }

  #cssmenu > ul li a {
    height: 40px;
    line-height: 40px;
    padding-left: 10px;
  }

  #cssmenu #menu-button:after {
    position: absolute;
    top: 22px;
    right: 17px;
    display: block;
    height: 4px;
    width: 20px;
    border-top: 2px solid #dddddd;
    border-bottom: 2px solid #dddddd;
    content: '';
    box-sizing: content-box;
  }

  #cssmenu #menu-button:before {
    position: absolute;
    top: 16px;
    right: 17px;
    display: block;
    height: 2px;
    width: 20px;
    background: #dddddd;
    content: '';
  }

  #cssmenu #menu-button.menu-opened:after {
    top: 30px;
    border: 0;
    height: 2px;
    width: 15px;
    background: #ffffff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #cssmenu #menu-button.menu-opened:before {
    top: 30px;
    background: #ffffff;
    width: 15px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #cssmenu .submenu-button {
    position: absolute;
    z-index: 9999;
    right: 0;
    top: 0;
    display: block;
    border-left: 1px solid rgba(120, 120, 120, 0.2);
    height: 46px;
    width: 46px;
    cursor: pointer;
  }

  #cssmenu .submenu-button.submenu-opened {
    background: #262626;
  }

  #cssmenu ul ul .submenu-button {
    height: 34px;
    width: 34px;
  }

  #cssmenu .submenu-button:after {
    position: absolute;
    top: 22px;
    right: 19px;
    width: 8px;
    height: 2px;
    display: block;
    background: #dddddd;
    content: '';
  }

  #cssmenu ul ul .submenu-button:after {
    top: 15px;
    right: 13px;
  }

  #cssmenu .submenu-button.submenu-opened:after {
    background: #ffffff;
  }

  #cssmenu .submenu-button:before {
    position: absolute;
    top: 19px;
    right: 22px;
    display: block;
    width: 2px;
    height: 8px;
    background: #dddddd;
    content: '';
  }

  #cssmenu ul ul .submenu-button:before {
    top: 12px;
    right: 16px;
  }

  #cssmenu .submenu-button.submenu-opened:before {
    display: none;
  }

  #cssmenu.select-list {
    padding: 5px;
  }

  #page-container #page-header {
    height: 50px !important;
  }
}

#cssmenu > ul > li.menuitemrent:hover > a {
  color: #63c6be;
}

#cssmenu,
#cssmenu ul,
#cssmenu ul li,
#cssmenu ul li a,
#cssmenu #menu-button {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  line-height: 1;
  display: block;
  position: relative;
  box-sizing: border-box;
  z-index: 2000;
}

#cssmenu:after,
#cssmenu > ul:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

#cssmenu #menu-button {
  display: block;
  float: left;
  z-index: 2001;
}

#cssmenu #menu-button img {
  height: 84px;
  padding: 10px;
  padding-left: 15px;
}

#cssmenu {
  font-family: Montserrat, sans-serif;
}

#cssmenu > ul > li {
  float: left;
}

#cssmenu.align-center > ul {
  font-size: 0;
  text-align: center;
}

#cssmenu.align-center > ul > li {
  display: inline-block;
  float: none;
}

#cssmenu > ul li a {
  height: 80px;
  line-height: 80px;
}

#cssmenu.align-center ul ul {
  text-align: left;
}

#cssmenu.align-right > ul {
  float: right;
  height: 80px;
  align-items: center;
}

#cssmenu > ul > li > a {
  padding-right: 30px;
  font-size: 14px;
  letter-spacing: 1px;
  text-decoration: none;
  color: #dddddd;
  font-weight: 700;
  text-transform: uppercase;
}

#cssmenu > ul > li:hover > a {
  color: #ffcb04;
}

#cssmenu > ul > li.has-sub > a {
  padding-right: 30px;
}

#cssmenu > ul > li.has-sub > a:after {
  position: absolute;
  top: 28px;
  right: 11px;
  width: 8px;
  height: 2px;
  display: block;
  background: #dddddd;
  content: '';
}

#cssmenu > ul > li.has-sub > a:before {
  position: absolute;
  top: 25px;
  right: 14px;
  display: block;
  width: 2px;
  height: 8px;
  background: #dddddd;
  content: '';
  transition: all .25s ease;
}

#cssmenu > ul > li.has-sub:hover > a:before {
  top: 28px;
  height: 0;
}

#cssmenu ul ul {
  position: absolute;
  left: -9999px;
}

#cssmenu.align-right ul ul {
  text-align: left;
}

#cssmenu ul ul li {
  height: 0;
  transition: all .25s ease;
}

#cssmenu li:hover > ul {
  left: auto;
}

#cssmenu.align-right li:hover > ul {
  left: auto;
  right: 0;
}

#cssmenu li:hover > ul > li {
  height: 35px;
}

#cssmenu ul ul ul {
  margin-left: 100%;
  top: 0;
}

#cssmenu.align-right ul ul ul {
  margin-left: 0;
  margin-right: 100%;
}

#cssmenu ul ul li a {
  border-bottom: 1px solid rgba(150, 150, 150, 0.15);
  padding: 11px 15px;
  width: 170px;
  font-size: 12px;
  text-decoration: none;
  color: #dddddd;
  font-weight: 400;
  background: #333333;
  white-space: nowrap;
}

#cssmenu ul ul li:last-child > a,
#cssmenu ul ul li.last-item > a {
  border-bottom: 0;
}

#cssmenu ul ul li:hover > a,
#cssmenu ul ul li a:hover {
  color: #ffcb04;
}

#cssmenu ul ul li.has-sub > a:after {
  position: absolute;
  top: 16px;
  right: 11px;
  width: 8px;
  height: 2px;
  display: block;
  background: #dddddd;
  content: '';
}

#cssmenu.align-right ul ul li.has-sub > a:after {
  right: auto;
  right: 11px;
}

#cssmenu ul ul li.has-sub > a:before {
  position: absolute;
  top: 13px;
  right: 14px;
  display: block;
  width: 2px;
  height: 8px;
  background: #dddddd;
  content: '';
  transition: all .25s ease;
}

#cssmenu.align-right ul ul li.has-sub > a:before {
  right: auto;
  right: 14px;
}

#cssmenu ul ul > li.has-sub:hover > a:before {
  top: 17px;
  height: 0;
}

@media screen and (max-width: 900px) {
  #cssmenu {
    width: 100%;
  }

  #cssmenu ul {
    width: 100%;
    display: none;
    background-color: #333333;
  }

  #cssmenu.align-center > ul {
    text-align: left;
  }

  #cssmenu ul li {
    width: 100%;
    border-top: 1px solid rgba(120, 120, 120, 0.2);
  }

  #cssmenu ul ul li,
  #cssmenu li:hover > ul > li {
    height: auto;
  }

  #cssmenu ul li a,
  #cssmenu ul ul li a {
    width: 100%;
    border-bottom: 0;
  }

  #cssmenu > ul > li {
    float: none;
  }

  #cssmenu ul ul li a {
    padding-left: 25px;
  }

  #cssmenu ul ul ul li a {
    padding-left: 35px;
  }

  #cssmenu ul ul li a {
    color: #dddddd;
    background: none;
  }

  #cssmenu ul ul li:hover > a,
  #cssmenu ul ul li.active > a {
    color: #ffcb04;
  }

  #cssmenu ul ul,
  #cssmenu ul ul ul,
  #cssmenu.align-right ul ul {
    position: relative;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: left;
  }

  #cssmenu > ul > li.has-sub > a:after,
  #cssmenu > ul > li.has-sub > a:before,
  #cssmenu ul ul > li.has-sub > a:after,
  #cssmenu ul ul > li.has-sub > a:before {
    display: none;
  }

  #cssmenu #menu-button {
    display: block;
    float: none;
  }

  #cssmenu #menu-button img {
    height: 50px;
    padding: 5px;
  }

  #cssmenu.align-right > ul {
    height: auto;
  }

  #cssmenu > ul li a {
    height: 40px;
    line-height: 40px;
    padding-left: 10px;
  }

  #cssmenu #menu-button:after {
    position: absolute;
    top: 22px;
    right: 17px;
    display: block;
    height: 4px;
    width: 20px;
    border-top: 2px solid #dddddd;
    border-bottom: 2px solid #dddddd;
    content: '';
    box-sizing: content-box;
  }

  #cssmenu #menu-button:before {
    position: absolute;
    top: 16px;
    right: 17px;
    display: block;
    height: 2px;
    width: 20px;
    background: #dddddd;
    content: '';
  }

  #cssmenu #menu-button.menu-opened:after {
    top: 30px;
    border: 0;
    height: 2px;
    width: 15px;
    background: #ffffff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #cssmenu #menu-button.menu-opened:before {
    top: 30px;
    background: #ffffff;
    width: 15px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #cssmenu .submenu-button {
    position: absolute;
    z-index: 9999;
    right: 0;
    top: 0;
    display: block;
    border-left: 1px solid rgba(120, 120, 120, 0.2);
    height: 46px;
    width: 46px;
    cursor: pointer;
  }

  #cssmenu .submenu-button.submenu-opened {
    background: #262626;
  }

  #cssmenu ul ul .submenu-button {
    height: 34px;
    width: 34px;
  }

  #cssmenu .submenu-button:after {
    position: absolute;
    top: 22px;
    right: 19px;
    width: 8px;
    height: 2px;
    display: block;
    background: #dddddd;
    content: '';
  }

  #cssmenu ul ul .submenu-button:after {
    top: 15px;
    right: 13px;
  }

  #cssmenu .submenu-button.submenu-opened:after {
    background: #ffffff;
  }

  #cssmenu .submenu-button:before {
    position: absolute;
    top: 19px;
    right: 22px;
    display: block;
    width: 2px;
    height: 8px;
    background: #dddddd;
    content: '';
  }

  #cssmenu ul ul .submenu-button:before {
    top: 12px;
    right: 16px;
  }

  #cssmenu .submenu-button.submenu-opened:before {
    display: none;
  }

  #cssmenu.select-list {
    padding: 5px;
  }

  #page-container #page-header {
    height: 50px !important;
  }
}

#cssmenu > ul > li.menuitemrent:hover > a {
  color: #63c6be;
}

nav {
  background-color: #333333;
}

#logo-mba-immobilier {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2001;
  background-color: #ffffff;
  border: #333333 solid 10px;
  border-right: 0px;
}

#logo-mba-immobilier img {
  padding: 10px;
  height: 90px;
}

#page-footer a {
  color: #ffffff;
}

#page-footer a:hover {
  color: #fac400;
  outline: none !important;
  text-decoration: none;
}

.map-marker {
  -webkit-animation: bounce 1s forwards;
  animation: bounce 1s forwards;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
  }

  50% {
    -webkit-transform: translate(0, -10px);
    transform: translate(0, -10px);
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
  }

  50% {
    -webkit-transform: translate(0, -10px);
    transform: translate(0, -10px);
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
  }
}

.owl-thumbs {
  display: inline-block;
}

.owl-thumbs .owl-thumb-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

#page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#page-container #page-header {
  flex: 0 0 auto;
  background-color: #333333;
  height: 80px;
}

#page-container #propose-goods {
  display: none;
}

#page-container #page-content {
  margin: auto;
  height: 100%;
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;
  flex-direction: column;
  background-color: #efefef;
  width: 100%;
}

#page-container #page-content .map-container-detail {
  height: 150px;
  position: relative;
  margin-bottom: 35px;
}

#page-container #page-content .map-container-side {
  height: 250px;
  position: relative;
  margin-bottom: 35px;
}

#page-container #page-content #title-over-map {
  position: absolute;
  z-index: 1000;
  bottom: -40px;
}

#page-container #page-content #realty-scroll {
  position: relative;
  padding-top: 15px;
}

#page-container #page-content .left-content {
  height: 100%;
  width: 35%;
  float: left;
  position: relative;
}

#page-container #page-content .right-content {
  height: 100%;
  width: 65%;
  float: right;
  margin: auto;
}

#page-container #page-content .map-container {
  width: 100%;
  height: 100%;
}

#page-container #page-content .map-container .items-list {
  height: 100%;
  width: 40%;
  float: left;
}

#page-container #page-content .map-container #map {
  width: 60%;
  height: 100%;
  float: right;
}

#page-container #page-content .page-title .title figure,
#page-container #page-content .page-title .info {
  font-size: 12px;
}

#page-container #page-content .page-title .info .type span {
  margin-right: 10px;
}

#page-container #page-content .page-title .info .type i {
  padding-right: 15px;
}

#page-container #page-content .page-title .title figure i {
  margin-right: 5px;
}

#page-container #page-content .page-title .title figure span:after {
  content: '/';
  margin-right: 10px;
  margin-left: 10px;
}

#page-container #page-content .page-title .title figure span:last-child:after {
  content: '';
  margin: 0;
}

#page-container #page-content .block-content {
  padding: 35px;
}

#page-container #page-content .block-title {
  font-size: 36px;
  margin-bottom: 30px;
}

#page-container #page-content #block-bg-image {
  padding: 2vw 3vw;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100;White+to+Transparent */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #faffff 0%, rgba(255, 255, 255, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fac400', endColorstr='#00ffffff', GradientType=0);
  /* IE6-9 */
  padding-bottom: 250px;
}

#page-container #page-content #block-bg-image .search-wrapper {
  position: relative;
  background: #fff;
  border: 1px solid #0c2340;
}

#page-container #page-content #block-bg-image .search-wrapper .search-input {
  height: 47px;
  padding: .375rem 1.125rem .375rem 3.375rem;
  border: none;
}

#page-container #page-content #social {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 3vw;
  padding-top: 40px;
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(250, 255, 255, 0) 0%, white 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fac400', endColorstr='#00ffffff', GradientType=0);
  /* IE6-9 */
}

#page-container #page-content #social .social-item {
  margin-left: 5px;
  float: right;
}

#page-container #page-content #social .social-item:hover {
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(51, 51, 51, 0) 0%, #333333 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
  /* IE6-9 */
}

#page-container #page-footer {
  flex: 0 0 auto;
  color: #ffffff;
  font-size: 11px;
  background-color: #333333;
  position: relative;
}

#page-container #page-footer .footer-left {
  float: left;
  padding: 5px;
}

#page-container #page-footer .footer-right {
  float: right;
  padding: 5px;
}

@media screen and (max-width: 767px) {
  .batch-list {
    font-size: 9px;
  }

  #page-footer {
    flex: 0 0 auto;
    color: #ffffff;
    font-size: 9px !important;
    background-color: #333333;
    position: relative;
  }

  .full-gallery .owl-item .slide {
    height: 250px !important;
  }

  #page-container #page-content .map-container-detail {
    margin-bottom: 30px;
  }

  #page-container #page-content #title-over-map {
    bottom: -30px;
  }

  #page-container #page-content .page-gradient {
    top: 120px;
  }

  #page-container #page-content #realty-scroll #detail-sidebar {
    order: 1;
  }

  #page-container #page-content #realty-scroll #detail-main-content {
    order: 0;
  }

  #page-container #page-content .scroll-content {
    overflow: unset;
  }

  #page-container #page-content .left-content {
    height: 100%;
    width: 100%;
  }

  #page-container #page-content .right-content {
    height: 100%;
    width: 100%;
    margin-top: 35px;
  }

  #page-container #page-content .map-container .items-list {
    padding: 8px 0px 8px 8px;
    height: 60%;
    width: 100%;
    float: left;
  }

  #page-container #page-content .map-container .items-list .item .item-specific span {
    font-size: 9px;
    margin: 0px 4px;
  }

  #page-container #page-content .map-container .items-list li {
    width: 100%;
  }

  #page-container #page-content .map-container #map {
    width: 100%;
    height: 40%;
    float: left;
  }

  #page-container #page-content .page-title .title h1 {
    font-size: 16px;
  }

  #page-container #page-content .page-title .title figure,
  #page-container #page-content .page-title .info {
    font-size: 9px;
  }

  #page-container #page-content .page-title .title figure {
    padding-top: 0;
    padding-bottom: 5px;
  }

  #page-container #page-content .page-title .info {
    position: absolute;
    left: 15px;
    bottom: 0;
  }

  #page-container #page-content .page-title .title figure i {
    margin-right: 3px;
  }

  #page-container #page-content .page-title .title figure span:after {
    margin-right: 5px;
    margin-left: 5px;
  }

  #page-container #page-content .block-content {
    padding: 5px;
  }
}

.full-gallery .owl-item img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120% !important;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.full-gallery .owl-item .slide {
  height: 480px;
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}
/* COLORS ----------------------------*/

/* EFFECTS ---------------------------*/

/* SPEEDS ----------------------------*/

/* SIZES -----------------------------*/

/* SPACING ---------------------------*/

/* OTHER VARIABLES -------------------*/

.ct-widget.ct-ignition {
  right: 95px;
  bottom: 100px;
  left: inherit !important;
  top: inherit !important;
}

.ct-widget .ct-ignition__button {
  border: none !important;
  border-radius: 50% !important;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28) !important;
  cursor: pointer !important;
  outline: none !important;
  padding: 0 !important;
  -webkit-user-drag: none !important;
  font-weight: bold !important;
  color: #ffffff !important;
  width: 46px !important;
  height: 46px !important;
}

.ct-widget .ct-ignition__button--edit {
  background-color: #1e6275 !important;
}

.ct-widget .ct-ignition__button--confirm {
  right: 10px !important;
}

.ct-widget .ct-ignition__button--cancel {
  background-color: #752413 !important;
  right: 54px !important;
  left: 0 !important;
}

.mfb-component__list .dropleft .dropdown-toggle::before {
  display: none;
}

.mfb-component__list .dropleft .dropdown-menu::before {
  display: none;
}

.mfb-component__list .dropleft .dropdown-menu::after,
.mfb-component__list .dropleft .dropdown-menu::before {
  display: none;
  left: 100%;
  top: 32px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.mfb-component__list .dropleft .dropdown-menu::after {
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #ffffff;
  border-width: 7px;
  margin-top: -7px;
}

.mfb-component__list .dropleft .dropdown-menu::before {
  border-color: rgba(194, 225, 245, 0);
  border-left-color: #bcbcbc;
  border-width: 8px;
  margin-top: -8px;
}

.mfb-component__list .dropleft:hover .dropdown-menu::after,
.mfb-component__list .dropleft:hover .dropdown-menu::before {
  display: inline-block;
}

@media (min-width: 979px) {
  .mfb-component__list .dropleft:hover .dropdown-menu {
    display: block;
  }

  .mfb-component__list .dropleft:hover .dropdown-menu > p {
    margin-top: -8px;
    padding-top: 2px;
    text-align: center;
    background-color: #cecece;
    padding-bottom: 2px;
    color: #212529;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: 5px;
    font-weight: bold;
  }
}

/*------------------------------------*\
    #BASE | Raw styles
\*------------------------------------*/

/**
 * The very core styling of the button.
 * These styles are shared by every instance of the button.
 * Styles placed here should NOT care about placement in the screen,
 * options chosen by the user or state of the button.
 */

.mfb-component,
.mfb-component--tl,
.mfb-component--tr,
.mfb-component--bl,
.mfb-component--br {
  box-sizing: border-box;
  margin: 0;
  position: fixed;
  white-space: nowrap;
  z-index: 100000;
  padding-left: 0;
  list-style: none;
}

.mfb-component *,
.mfb-component--tl *,
.mfb-component--tr *,
.mfb-component--bl *,
.mfb-component--br *,
.mfb-component *:before,
.mfb-component--tl *:before,
.mfb-component--tr *:before,
.mfb-component--bl *:before,
.mfb-component--br *:before,
.mfb-component *:after,
.mfb-component--tl *:after,
.mfb-component--tr *:after,
.mfb-component--bl *:after,
.mfb-component--br *:after {
  box-sizing: inherit;
}

/*------------------------------------*\
    #BASE | Modifiers
\*------------------------------------*/

/**
 * These styles depends on the placement of the button.
 * Styles can be:
 * 1. Top-left:  modified by the " --tl " suffix.
 * 2. Top-right: modified by the " --tr " suffix.
 * 3. Bottom-left:  modified by the " --bl " suffix.
 * 4. Bottom-right: modified by the " --br " suffix.
 */

.mfb-component--tl {
  left: 0;
  top: 0;
}

.mfb-component--tr {
  right: 0;
  top: 0;
}

.mfb-component--bl {
  left: 0;
  bottom: 0;
}

.mfb-component--br {
  right: 50px;
  bottom: 100px;
}

/*------------------------------------*\
    #BUTTONS | Base
\*------------------------------------*/

.mfb-component__button,
.mfb-component__button--main,
.mfb-component__button--child {
  background-color: #643375;
  display: inline-block;
  position: relative;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  cursor: pointer;
  outline: none;
  padding: 0;
  position: relative;
  -webkit-user-drag: none;
  font-weight: bold;
  color: #f1f1f1;
}

/**
 * This is the unordered list for the list items that contain
 * the child buttons.
 *
 */

.mfb-component__wrap > .mfb-component__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mfb-component__wrap > .mfb-component__list > li {
  display: block;
  position: absolute;
  top: 0;
  right: 6px;
  padding: 15px 0;
  margin: -15px 0;
}

/**
 * These are the basic styles for all the icons inside the main button
 */

.mfb-component__icon,
.mfb-component__main-icon--active,
.mfb-component__main-icon--resting,
.mfb-component__child-icon {
  position: absolute;
  font-size: 18px;
  text-align: center;
  line-height: 46px;
  width: 100%;
}

.mfb-component__wrap {
  padding: 0;
  margin: 0;
}

[data-mfb-toggle="hover"]:hover .mfb-component__icon,
[data-mfb-toggle="hover"]:hover .mfb-component__main-icon--active,
[data-mfb-toggle="hover"]:hover .mfb-component__main-icon--resting,
[data-mfb-toggle="hover"]:hover .mfb-component__child-icon,
[data-mfb-state="open"] .mfb-component__icon,
[data-mfb-state="open"] .mfb-component__main-icon--active,
[data-mfb-state="open"] .mfb-component__main-icon--resting,
[data-mfb-state="open"] .mfb-component__child-icon {
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}

/*------------------------------------*\
    #BUTTONS | Modifiers
\*------------------------------------*/

.mfb-component__button--main {
  height: 46px;
  width: 46px;
  z-index: 20;
}

.mfb-component__button--child {
  height: 36px;
  width: 36px;
}

.mfb-component__main-icon--active,
.mfb-component__main-icon--resting {
  -webkit-transform: scale(1) rotate(360deg);
  transform: scale(1) rotate(360deg);
  transition: -webkit-transform 150ms cubic-bezier(0.4, 0, 1, 1);
  transition: transform 150ms cubic-bezier(0.4, 0, 1, 1);
  transition: transform 150ms cubic-bezier(0.4, 0, 1, 1), -webkit-transform 150ms cubic-bezier(0.4, 0, 1, 1);
}

.mfb-component__child-icon,
.mfb-component__child-icon {
  line-height: 36px;
  font-size: 11.57143px;
}

.mfb-component__main-icon--active {
  opacity: 0;
}

[data-mfb-toggle="hover"]:hover .mfb-component__main-icon,
[data-mfb-state="open"] .mfb-component__main-icon {
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}

[data-mfb-toggle="hover"]:hover .mfb-component__main-icon--resting,
[data-mfb-state="open"] .mfb-component__main-icon--resting {
  opacity: 0;
}

[data-mfb-toggle="hover"]:hover .mfb-component__main-icon--active,
[data-mfb-state="open"] .mfb-component__main-icon--active {
  opacity: 1;
}

/*------------------------------------*\
    #BUTTONS | Animations
\*------------------------------------*/

/**
 * SLIDE IN + FADE
 * When hovering the main button, the child buttons slide out from beneath
 * the main button while transitioning from transparent to opaque.
 *
 */

.mfb-component--tl.mfb-slidein .mfb-component__list li,
.mfb-component--tr.mfb-slidein .mfb-component__list li {
  opacity: 0;
  transition: all 0.5s;
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li,
.mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li,
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li,
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li {
  opacity: 1;
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(45px);
  transform: translateY(45px);
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(90px);
  transform: translateY(90px);
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(135px);
  transform: translateY(135px);
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(180px);
  transform: translateY(180px);
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(5),
.mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(5),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(5),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(225px);
  transform: translateY(225px);
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(6),
.mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(6),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(6),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(6) {
  -webkit-transform: translateY(270px);
  transform: translateY(270px);
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(7),
.mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(7),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(7),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(7) {
  -webkit-transform: translateY(315px);
  transform: translateY(315px);
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(8),
.mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(8),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(8),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(8) {
  -webkit-transform: translateY(360px);
  transform: translateY(360px);
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(9),
.mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(9),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(9),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(9) {
  -webkit-transform: translateY(405px);
  transform: translateY(405px);
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(10),
.mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(10),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(10),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(10) {
  -webkit-transform: translateY(450px);
  transform: translateY(450px);
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(11),
.mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(11),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(11),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(11) {
  -webkit-transform: translateY(495px);
  transform: translateY(495px);
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(12),
.mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(12),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(12),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(12) {
  -webkit-transform: translateY(540px);
  transform: translateY(540px);
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(13),
.mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(13),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(13),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(13) {
  -webkit-transform: translateY(585px);
  transform: translateY(585px);
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(14),
.mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(14),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(14),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(14) {
  -webkit-transform: translateY(630px);
  transform: translateY(630px);
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(15),
.mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(15),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(15),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(15) {
  -webkit-transform: translateY(675px);
  transform: translateY(675px);
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(16),
.mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(16),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(16),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(16) {
  -webkit-transform: translateY(720px);
  transform: translateY(720px);
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(17),
.mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(17),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(17),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(17) {
  -webkit-transform: translateY(765px);
  transform: translateY(765px);
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(18),
.mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(18),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(18),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(18) {
  -webkit-transform: translateY(810px);
  transform: translateY(810px);
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(19),
.mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(19),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(19),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(19) {
  -webkit-transform: translateY(855px);
  transform: translateY(855px);
}

.mfb-component--tl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(20),
.mfb-component--tl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(20),
.mfb-component--tr.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(20),
.mfb-component--tr.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(20) {
  -webkit-transform: translateY(900px);
  transform: translateY(900px);
}

.mfb-component--bl.mfb-slidein .mfb-component__list li,
.mfb-component--br.mfb-slidein .mfb-component__list li {
  opacity: 0;
  transition: all 0.5s;
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li,
.mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li,
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li,
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li {
  opacity: 1;
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(-45px);
  transform: translateY(-45px);
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(-90px);
  transform: translateY(-90px);
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(-135px);
  transform: translateY(-135px);
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(-180px);
  transform: translateY(-180px);
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(5),
.mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(5),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(5),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(-225px);
  transform: translateY(-225px);
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(6),
.mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(6),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(6),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(6) {
  -webkit-transform: translateY(-270px);
  transform: translateY(-270px);
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(7),
.mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(7),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(7),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(7) {
  -webkit-transform: translateY(-315px);
  transform: translateY(-315px);
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(8),
.mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(8),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(8),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(8) {
  -webkit-transform: translateY(-360px);
  transform: translateY(-360px);
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(9),
.mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(9),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(9),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(9) {
  -webkit-transform: translateY(-405px);
  transform: translateY(-405px);
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(10),
.mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(10),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(10),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(10) {
  -webkit-transform: translateY(-450px);
  transform: translateY(-450px);
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(11),
.mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(11),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(11),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(11) {
  -webkit-transform: translateY(-495px);
  transform: translateY(-495px);
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(12),
.mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(12),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(12),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(12) {
  -webkit-transform: translateY(-540px);
  transform: translateY(-540px);
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(13),
.mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(13),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(13),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(13) {
  -webkit-transform: translateY(-585px);
  transform: translateY(-585px);
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(14),
.mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(14),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(14),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(14) {
  -webkit-transform: translateY(-630px);
  transform: translateY(-630px);
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(15),
.mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(15),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(15),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(15) {
  -webkit-transform: translateY(-675px);
  transform: translateY(-675px);
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(16),
.mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(16),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(16),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(16) {
  -webkit-transform: translateY(-720px);
  transform: translateY(-720px);
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(17),
.mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(17),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(17),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(17) {
  -webkit-transform: translateY(-765px);
  transform: translateY(-765px);
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(18),
.mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(18),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(18),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(18) {
  -webkit-transform: translateY(-810px);
  transform: translateY(-810px);
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(19),
.mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(19),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(19),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(19) {
  -webkit-transform: translateY(-855px);
  transform: translateY(-855px);
}

.mfb-component--bl.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(20),
.mfb-component--bl.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(20),
.mfb-component--br.mfb-slidein[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(20),
.mfb-component--br.mfb-slidein[data-mfb-state="open"] .mfb-component__list li:nth-child(20) {
  -webkit-transform: translateY(-900px);
  transform: translateY(-900px);
}

/**
 * SLIDE IN SPRING
 * When hovering the main button, the child buttons slide out from beneath
 * the main button while transitioning from transparent to opaque.
 *
 */

.mfb-component--tl.mfb-slidein-spring .mfb-component__list li,
.mfb-component--tr.mfb-slidein-spring .mfb-component__list li {
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li,
.mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li,
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li,
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li {
  opacity: 1;
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(45px);
  transform: translateY(45px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(90px);
  transform: translateY(90px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(135px);
  transform: translateY(135px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(180px);
  transform: translateY(180px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(5),
.mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(5),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(5),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(225px);
  transform: translateY(225px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(6),
.mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(6),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(6),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(6) {
  -webkit-transform: translateY(270px);
  transform: translateY(270px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(7),
.mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(7),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(7),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(7) {
  -webkit-transform: translateY(315px);
  transform: translateY(315px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(8),
.mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(8),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(8),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(8) {
  -webkit-transform: translateY(360px);
  transform: translateY(360px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(9),
.mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(9),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(9),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(9) {
  -webkit-transform: translateY(405px);
  transform: translateY(405px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(10),
.mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(10),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(10),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(10) {
  -webkit-transform: translateY(450px);
  transform: translateY(450px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(11),
.mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(11),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(11),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(11) {
  -webkit-transform: translateY(495px);
  transform: translateY(495px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(12),
.mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(12),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(12),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(12) {
  -webkit-transform: translateY(540px);
  transform: translateY(540px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(13),
.mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(13),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(13),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(13) {
  -webkit-transform: translateY(585px);
  transform: translateY(585px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(14),
.mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(14),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(14),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(14) {
  -webkit-transform: translateY(630px);
  transform: translateY(630px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(15),
.mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(15),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(15),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(15) {
  -webkit-transform: translateY(675px);
  transform: translateY(675px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(16),
.mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(16),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(16),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(16) {
  -webkit-transform: translateY(720px);
  transform: translateY(720px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(17),
.mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(17),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(17),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(17) {
  -webkit-transform: translateY(765px);
  transform: translateY(765px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(18),
.mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(18),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(18),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(18) {
  -webkit-transform: translateY(810px);
  transform: translateY(810px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(19),
.mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(19),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(19),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(19) {
  -webkit-transform: translateY(855px);
  transform: translateY(855px);
}

.mfb-component--tl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(20),
.mfb-component--tl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(20),
.mfb-component--tr.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(20),
.mfb-component--tr.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(20) {
  -webkit-transform: translateY(900px);
  transform: translateY(900px);
}

.mfb-component--bl.mfb-slidein-spring .mfb-component__list li,
.mfb-component--br.mfb-slidein-spring .mfb-component__list li {
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li,
.mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li,
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li,
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li {
  opacity: 1;
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(-45px);
  transform: translateY(-45px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(-90px);
  transform: translateY(-90px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(-135px);
  transform: translateY(-135px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(-180px);
  transform: translateY(-180px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(5),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(5),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(5),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(-225px);
  transform: translateY(-225px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(6),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(6),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(6),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(6) {
  -webkit-transform: translateY(-270px);
  transform: translateY(-270px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(7),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(7),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(7),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(7) {
  -webkit-transform: translateY(-315px);
  transform: translateY(-315px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(8),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(8),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(8),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(8) {
  -webkit-transform: translateY(-360px);
  transform: translateY(-360px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(9),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(9),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(9),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(9) {
  -webkit-transform: translateY(-405px);
  transform: translateY(-405px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(10),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(10),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(10),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(10) {
  -webkit-transform: translateY(-450px);
  transform: translateY(-450px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(11),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(11),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(11),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(11) {
  -webkit-transform: translateY(-495px);
  transform: translateY(-495px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(12),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(12),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(12),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(12) {
  -webkit-transform: translateY(-540px);
  transform: translateY(-540px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(13),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(13),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(13),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(13) {
  -webkit-transform: translateY(-585px);
  transform: translateY(-585px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(14),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(14),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(14),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(14) {
  -webkit-transform: translateY(-630px);
  transform: translateY(-630px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(15),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(15),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(15),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(15) {
  -webkit-transform: translateY(-675px);
  transform: translateY(-675px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(16),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(16),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(16),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(16) {
  -webkit-transform: translateY(-720px);
  transform: translateY(-720px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(17),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(17),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(17),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(17) {
  -webkit-transform: translateY(-765px);
  transform: translateY(-765px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(18),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(18),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(18),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(18) {
  -webkit-transform: translateY(-810px);
  transform: translateY(-810px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(19),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(19),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(19),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(19) {
  -webkit-transform: translateY(-855px);
  transform: translateY(-855px);
}

.mfb-component--bl.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(20),
.mfb-component--bl.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(20),
.mfb-component--br.mfb-slidein-spring[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(20),
.mfb-component--br.mfb-slidein-spring[data-mfb-state="open"] .mfb-component__list li:nth-child(20) {
  -webkit-transform: translateY(-900px);
  transform: translateY(-900px);
}

/**
 * ZOOM-IN
 * When hovering the main button, the child buttons grow
 * from zero to normal size.
 *
 */

.mfb-component--tl.mfb-zoomin .mfb-component__list li,
.mfb-component--tr.mfb-zoomin .mfb-component__list li {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(45px) scale(0);
  transform: translateY(45px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.95s;
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(90px) scale(0);
  transform: translateY(90px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.9s;
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(135px) scale(0);
  transform: translateY(135px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.85s;
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(180px) scale(0);
  transform: translateY(180px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.8s;
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(5),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(225px) scale(0);
  transform: translateY(225px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.75s;
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(6),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(6) {
  -webkit-transform: translateY(270px) scale(0);
  transform: translateY(270px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.7s;
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(7),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(7) {
  -webkit-transform: translateY(315px) scale(0);
  transform: translateY(315px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.65s;
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(8),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(8) {
  -webkit-transform: translateY(360px) scale(0);
  transform: translateY(360px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.6s;
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(9),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(9) {
  -webkit-transform: translateY(405px) scale(0);
  transform: translateY(405px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.55s;
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(10),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(10) {
  -webkit-transform: translateY(450px) scale(0);
  transform: translateY(450px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.5s;
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(11),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(11) {
  -webkit-transform: translateY(495px) scale(0);
  transform: translateY(495px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.45s;
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(12),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(12) {
  -webkit-transform: translateY(540px) scale(0);
  transform: translateY(540px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.4s;
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(13),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(13) {
  -webkit-transform: translateY(585px) scale(0);
  transform: translateY(585px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.35s;
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(14),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(14) {
  -webkit-transform: translateY(630px) scale(0);
  transform: translateY(630px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.3s;
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(15),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(15) {
  -webkit-transform: translateY(675px) scale(0);
  transform: translateY(675px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.25s;
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(16),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(16) {
  -webkit-transform: translateY(720px) scale(0);
  transform: translateY(720px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.2s;
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(17),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(17) {
  -webkit-transform: translateY(765px) scale(0);
  transform: translateY(765px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.15s;
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(18),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(18) {
  -webkit-transform: translateY(810px) scale(0);
  transform: translateY(810px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.1s;
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(19),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(19) {
  -webkit-transform: translateY(855px) scale(0);
  transform: translateY(855px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.05s;
}

.mfb-component--tl.mfb-zoomin .mfb-component__list li:nth-child(20),
.mfb-component--tr.mfb-zoomin .mfb-component__list li:nth-child(20) {
  -webkit-transform: translateY(900px) scale(0);
  transform: translateY(900px) scale(0);
  transition: all 0.5s;
  transition-delay: 0s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(45px) scale(1);
  transform: translateY(45px) scale(1);
  transition-delay: 0.05s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(90px) scale(1);
  transform: translateY(90px) scale(1);
  transition-delay: 0.1s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(135px) scale(1);
  transform: translateY(135px) scale(1);
  transition-delay: 0.15s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(180px) scale(1);
  transform: translateY(180px) scale(1);
  transition-delay: 0.2s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(5),
.mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(5),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(5),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(225px) scale(1);
  transform: translateY(225px) scale(1);
  transition-delay: 0.25s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(6),
.mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(6),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(6),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(6) {
  -webkit-transform: translateY(270px) scale(1);
  transform: translateY(270px) scale(1);
  transition-delay: 0.3s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(7),
.mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(7),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(7),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(7) {
  -webkit-transform: translateY(315px) scale(1);
  transform: translateY(315px) scale(1);
  transition-delay: 0.35s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(8),
.mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(8),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(8),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(8) {
  -webkit-transform: translateY(360px) scale(1);
  transform: translateY(360px) scale(1);
  transition-delay: 0.4s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(9),
.mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(9),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(9),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(9) {
  -webkit-transform: translateY(405px) scale(1);
  transform: translateY(405px) scale(1);
  transition-delay: 0.45s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(10),
.mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(10),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(10),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(10) {
  -webkit-transform: translateY(450px) scale(1);
  transform: translateY(450px) scale(1);
  transition-delay: 0.5s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(11),
.mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(11),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(11),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(11) {
  -webkit-transform: translateY(495px) scale(1);
  transform: translateY(495px) scale(1);
  transition-delay: 0.55s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(12),
.mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(12),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(12),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(12) {
  -webkit-transform: translateY(540px) scale(1);
  transform: translateY(540px) scale(1);
  transition-delay: 0.6s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(13),
.mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(13),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(13),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(13) {
  -webkit-transform: translateY(585px) scale(1);
  transform: translateY(585px) scale(1);
  transition-delay: 0.65s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(14),
.mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(14),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(14),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(14) {
  -webkit-transform: translateY(630px) scale(1);
  transform: translateY(630px) scale(1);
  transition-delay: 0.7s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(15),
.mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(15),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(15),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(15) {
  -webkit-transform: translateY(675px) scale(1);
  transform: translateY(675px) scale(1);
  transition-delay: 0.75s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(16),
.mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(16),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(16),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(16) {
  -webkit-transform: translateY(720px) scale(1);
  transform: translateY(720px) scale(1);
  transition-delay: 0.8s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(17),
.mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(17),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(17),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(17) {
  -webkit-transform: translateY(765px) scale(1);
  transform: translateY(765px) scale(1);
  transition-delay: 0.85s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(18),
.mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(18),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(18),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(18) {
  -webkit-transform: translateY(810px) scale(1);
  transform: translateY(810px) scale(1);
  transition-delay: 0.9s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(19),
.mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(19),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(19),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(19) {
  -webkit-transform: translateY(855px) scale(1);
  transform: translateY(855px) scale(1);
  transition-delay: 0.95s;
}

.mfb-component--tl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(20),
.mfb-component--tl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(20),
.mfb-component--tr.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(20),
.mfb-component--tr.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(20) {
  -webkit-transform: translateY(900px) scale(1);
  transform: translateY(900px) scale(1);
  transition-delay: 1s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li,
.mfb-component--br.mfb-zoomin .mfb-component__list li {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(-45px) scale(0);
  transform: translateY(-45px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.95s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(-90px) scale(0);
  transform: translateY(-90px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.9s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(-135px) scale(0);
  transform: translateY(-135px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.85s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(-180px) scale(0);
  transform: translateY(-180px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.8s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(5),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(-225px) scale(0);
  transform: translateY(-225px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.75s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(6),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(6) {
  -webkit-transform: translateY(-270px) scale(0);
  transform: translateY(-270px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.7s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(7),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(7) {
  -webkit-transform: translateY(-315px) scale(0);
  transform: translateY(-315px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.65s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(8),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(8) {
  -webkit-transform: translateY(-360px) scale(0);
  transform: translateY(-360px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.6s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(9),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(9) {
  -webkit-transform: translateY(-405px) scale(0);
  transform: translateY(-405px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.55s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(10),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(10) {
  -webkit-transform: translateY(-450px) scale(0);
  transform: translateY(-450px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.5s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(11),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(11) {
  -webkit-transform: translateY(-495px) scale(0);
  transform: translateY(-495px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.45s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(12),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(12) {
  -webkit-transform: translateY(-540px) scale(0);
  transform: translateY(-540px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.4s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(13),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(13) {
  -webkit-transform: translateY(-585px) scale(0);
  transform: translateY(-585px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.35s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(14),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(14) {
  -webkit-transform: translateY(-630px) scale(0);
  transform: translateY(-630px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.3s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(15),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(15) {
  -webkit-transform: translateY(-675px) scale(0);
  transform: translateY(-675px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.25s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(16),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(16) {
  -webkit-transform: translateY(-720px) scale(0);
  transform: translateY(-720px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.2s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(17),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(17) {
  -webkit-transform: translateY(-765px) scale(0);
  transform: translateY(-765px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.15s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(18),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(18) {
  -webkit-transform: translateY(-810px) scale(0);
  transform: translateY(-810px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.1s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(19),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(19) {
  -webkit-transform: translateY(-855px) scale(0);
  transform: translateY(-855px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.05s;
}

.mfb-component--bl.mfb-zoomin .mfb-component__list li:nth-child(20),
.mfb-component--br.mfb-zoomin .mfb-component__list li:nth-child(20) {
  -webkit-transform: translateY(-900px) scale(0);
  transform: translateY(-900px) scale(0);
  transition: all 0.5s;
  transition-delay: 0s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(-45px) scale(1);
  transform: translateY(-45px) scale(1);
  transition-delay: 0.05s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(-90px) scale(1);
  transform: translateY(-90px) scale(1);
  transition-delay: 0.1s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(-135px) scale(1);
  transform: translateY(-135px) scale(1);
  transition-delay: 0.15s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(-180px) scale(1);
  transform: translateY(-180px) scale(1);
  transition-delay: 0.2s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(5),
.mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(5),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(5),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(-225px) scale(1);
  transform: translateY(-225px) scale(1);
  transition-delay: 0.25s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(6),
.mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(6),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(6),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(6) {
  -webkit-transform: translateY(-270px) scale(1);
  transform: translateY(-270px) scale(1);
  transition-delay: 0.3s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(7),
.mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(7),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(7),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(7) {
  -webkit-transform: translateY(-315px) scale(1);
  transform: translateY(-315px) scale(1);
  transition-delay: 0.35s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(8),
.mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(8),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(8),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(8) {
  -webkit-transform: translateY(-360px) scale(1);
  transform: translateY(-360px) scale(1);
  transition-delay: 0.4s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(9),
.mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(9),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(9),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(9) {
  -webkit-transform: translateY(-405px) scale(1);
  transform: translateY(-405px) scale(1);
  transition-delay: 0.45s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(10),
.mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(10),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(10),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(10) {
  -webkit-transform: translateY(-450px) scale(1);
  transform: translateY(-450px) scale(1);
  transition-delay: 0.5s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(11),
.mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(11),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(11),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(11) {
  -webkit-transform: translateY(-495px) scale(1);
  transform: translateY(-495px) scale(1);
  transition-delay: 0.55s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(12),
.mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(12),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(12),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(12) {
  -webkit-transform: translateY(-540px) scale(1);
  transform: translateY(-540px) scale(1);
  transition-delay: 0.6s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(13),
.mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(13),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(13),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(13) {
  -webkit-transform: translateY(-585px) scale(1);
  transform: translateY(-585px) scale(1);
  transition-delay: 0.65s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(14),
.mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(14),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(14),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(14) {
  -webkit-transform: translateY(-630px) scale(1);
  transform: translateY(-630px) scale(1);
  transition-delay: 0.7s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(15),
.mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(15),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(15),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(15) {
  -webkit-transform: translateY(-675px) scale(1);
  transform: translateY(-675px) scale(1);
  transition-delay: 0.75s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(16),
.mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(16),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(16),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(16) {
  -webkit-transform: translateY(-720px) scale(1);
  transform: translateY(-720px) scale(1);
  transition-delay: 0.8s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(17),
.mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(17),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(17),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(17) {
  -webkit-transform: translateY(-765px) scale(1);
  transform: translateY(-765px) scale(1);
  transition-delay: 0.85s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(18),
.mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(18),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(18),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(18) {
  -webkit-transform: translateY(-810px) scale(1);
  transform: translateY(-810px) scale(1);
  transition-delay: 0.9s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(19),
.mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(19),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(19),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(19) {
  -webkit-transform: translateY(-855px) scale(1);
  transform: translateY(-855px) scale(1);
  transition-delay: 0.95s;
}

.mfb-component--bl.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(20),
.mfb-component--bl.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(20),
.mfb-component--br.mfb-zoomin[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(20),
.mfb-component--br.mfb-zoomin[data-mfb-state="open"] .mfb-component__list li:nth-child(20) {
  -webkit-transform: translateY(-900px) scale(1);
  transform: translateY(-900px) scale(1);
  transition-delay: 1s;
}

/**
 * FOUNTAIN
 * When hovering the main button the child buttons
 * jump into view from outside the viewport
 */

.mfb-component--tl.mfb-fountain .mfb-component__list li,
.mfb-component--tr.mfb-fountain .mfb-component__list li {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(-45px) scale(0);
  transform: translateY(-45px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.95s;
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(-90px) scale(0);
  transform: translateY(-90px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.9s;
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(-135px) scale(0);
  transform: translateY(-135px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.85s;
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(-180px) scale(0);
  transform: translateY(-180px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.8s;
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(5),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(-225px) scale(0);
  transform: translateY(-225px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.75s;
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(6),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(6) {
  -webkit-transform: translateY(-270px) scale(0);
  transform: translateY(-270px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.7s;
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(7),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(7) {
  -webkit-transform: translateY(-315px) scale(0);
  transform: translateY(-315px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.65s;
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(8),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(8) {
  -webkit-transform: translateY(-360px) scale(0);
  transform: translateY(-360px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.6s;
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(9),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(9) {
  -webkit-transform: translateY(-405px) scale(0);
  transform: translateY(-405px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.55s;
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(10),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(10) {
  -webkit-transform: translateY(-450px) scale(0);
  transform: translateY(-450px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.5s;
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(11),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(11) {
  -webkit-transform: translateY(-495px) scale(0);
  transform: translateY(-495px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.45s;
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(12),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(12) {
  -webkit-transform: translateY(-540px) scale(0);
  transform: translateY(-540px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.4s;
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(13),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(13) {
  -webkit-transform: translateY(-585px) scale(0);
  transform: translateY(-585px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.35s;
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(14),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(14) {
  -webkit-transform: translateY(-630px) scale(0);
  transform: translateY(-630px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.3s;
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(15),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(15) {
  -webkit-transform: translateY(-675px) scale(0);
  transform: translateY(-675px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.25s;
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(16),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(16) {
  -webkit-transform: translateY(-720px) scale(0);
  transform: translateY(-720px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.2s;
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(17),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(17) {
  -webkit-transform: translateY(-765px) scale(0);
  transform: translateY(-765px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.15s;
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(18),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(18) {
  -webkit-transform: translateY(-810px) scale(0);
  transform: translateY(-810px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.1s;
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(19),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(19) {
  -webkit-transform: translateY(-855px) scale(0);
  transform: translateY(-855px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.05s;
}

.mfb-component--tl.mfb-fountain .mfb-component__list li:nth-child(20),
.mfb-component--tr.mfb-fountain .mfb-component__list li:nth-child(20) {
  -webkit-transform: translateY(-900px) scale(0);
  transform: translateY(-900px) scale(0);
  transition: all 0.5s;
  transition-delay: 0s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(45px) scale(1);
  transform: translateY(45px) scale(1);
  transition-delay: 0.05s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(90px) scale(1);
  transform: translateY(90px) scale(1);
  transition-delay: 0.1s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(135px) scale(1);
  transform: translateY(135px) scale(1);
  transition-delay: 0.15s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(180px) scale(1);
  transform: translateY(180px) scale(1);
  transition-delay: 0.2s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(5),
.mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(5),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(5),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(225px) scale(1);
  transform: translateY(225px) scale(1);
  transition-delay: 0.25s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(6),
.mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(6),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(6),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(6) {
  -webkit-transform: translateY(270px) scale(1);
  transform: translateY(270px) scale(1);
  transition-delay: 0.3s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(7),
.mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(7),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(7),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(7) {
  -webkit-transform: translateY(315px) scale(1);
  transform: translateY(315px) scale(1);
  transition-delay: 0.35s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(8),
.mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(8),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(8),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(8) {
  -webkit-transform: translateY(360px) scale(1);
  transform: translateY(360px) scale(1);
  transition-delay: 0.4s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(9),
.mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(9),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(9),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(9) {
  -webkit-transform: translateY(405px) scale(1);
  transform: translateY(405px) scale(1);
  transition-delay: 0.45s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(10),
.mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(10),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(10),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(10) {
  -webkit-transform: translateY(450px) scale(1);
  transform: translateY(450px) scale(1);
  transition-delay: 0.5s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(11),
.mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(11),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(11),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(11) {
  -webkit-transform: translateY(495px) scale(1);
  transform: translateY(495px) scale(1);
  transition-delay: 0.55s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(12),
.mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(12),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(12),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(12) {
  -webkit-transform: translateY(540px) scale(1);
  transform: translateY(540px) scale(1);
  transition-delay: 0.6s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(13),
.mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(13),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(13),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(13) {
  -webkit-transform: translateY(585px) scale(1);
  transform: translateY(585px) scale(1);
  transition-delay: 0.65s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(14),
.mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(14),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(14),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(14) {
  -webkit-transform: translateY(630px) scale(1);
  transform: translateY(630px) scale(1);
  transition-delay: 0.7s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(15),
.mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(15),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(15),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(15) {
  -webkit-transform: translateY(675px) scale(1);
  transform: translateY(675px) scale(1);
  transition-delay: 0.75s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(16),
.mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(16),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(16),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(16) {
  -webkit-transform: translateY(720px) scale(1);
  transform: translateY(720px) scale(1);
  transition-delay: 0.8s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(17),
.mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(17),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(17),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(17) {
  -webkit-transform: translateY(765px) scale(1);
  transform: translateY(765px) scale(1);
  transition-delay: 0.85s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(18),
.mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(18),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(18),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(18) {
  -webkit-transform: translateY(810px) scale(1);
  transform: translateY(810px) scale(1);
  transition-delay: 0.9s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(19),
.mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(19),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(19),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(19) {
  -webkit-transform: translateY(855px) scale(1);
  transform: translateY(855px) scale(1);
  transition-delay: 0.95s;
}

.mfb-component--tl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(20),
.mfb-component--tl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(20),
.mfb-component--tr.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(20),
.mfb-component--tr.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(20) {
  -webkit-transform: translateY(900px) scale(1);
  transform: translateY(900px) scale(1);
  transition-delay: 1s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li,
.mfb-component--br.mfb-fountain .mfb-component__list li {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(45px) scale(0);
  transform: translateY(45px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.95s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(90px) scale(0);
  transform: translateY(90px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.9s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(135px) scale(0);
  transform: translateY(135px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.85s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(180px) scale(0);
  transform: translateY(180px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.8s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(5),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(225px) scale(0);
  transform: translateY(225px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.75s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(6),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(6) {
  -webkit-transform: translateY(270px) scale(0);
  transform: translateY(270px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.7s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(7),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(7) {
  -webkit-transform: translateY(315px) scale(0);
  transform: translateY(315px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.65s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(8),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(8) {
  -webkit-transform: translateY(360px) scale(0);
  transform: translateY(360px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.6s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(9),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(9) {
  -webkit-transform: translateY(405px) scale(0);
  transform: translateY(405px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.55s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(10),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(10) {
  -webkit-transform: translateY(450px) scale(0);
  transform: translateY(450px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.5s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(11),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(11) {
  -webkit-transform: translateY(495px) scale(0);
  transform: translateY(495px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.45s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(12),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(12) {
  -webkit-transform: translateY(540px) scale(0);
  transform: translateY(540px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.4s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(13),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(13) {
  -webkit-transform: translateY(585px) scale(0);
  transform: translateY(585px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.35s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(14),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(14) {
  -webkit-transform: translateY(630px) scale(0);
  transform: translateY(630px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.3s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(15),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(15) {
  -webkit-transform: translateY(675px) scale(0);
  transform: translateY(675px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.25s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(16),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(16) {
  -webkit-transform: translateY(720px) scale(0);
  transform: translateY(720px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.2s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(17),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(17) {
  -webkit-transform: translateY(765px) scale(0);
  transform: translateY(765px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.15s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(18),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(18) {
  -webkit-transform: translateY(810px) scale(0);
  transform: translateY(810px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.1s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(19),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(19) {
  -webkit-transform: translateY(855px) scale(0);
  transform: translateY(855px) scale(0);
  transition: all 0.5s;
  transition-delay: 0.05s;
}

.mfb-component--bl.mfb-fountain .mfb-component__list li:nth-child(20),
.mfb-component--br.mfb-fountain .mfb-component__list li:nth-child(20) {
  -webkit-transform: translateY(900px) scale(0);
  transform: translateY(900px) scale(0);
  transition: all 0.5s;
  transition-delay: 0s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(1),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(1) {
  -webkit-transform: translateY(-45px) scale(1);
  transform: translateY(-45px) scale(1);
  transition-delay: 0.05s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(2),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(2) {
  -webkit-transform: translateY(-90px) scale(1);
  transform: translateY(-90px) scale(1);
  transition-delay: 0.1s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(3),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(3) {
  -webkit-transform: translateY(-135px) scale(1);
  transform: translateY(-135px) scale(1);
  transition-delay: 0.15s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(4),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(4) {
  -webkit-transform: translateY(-180px) scale(1);
  transform: translateY(-180px) scale(1);
  transition-delay: 0.2s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(5),
.mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(5),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(5),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(5) {
  -webkit-transform: translateY(-225px) scale(1);
  transform: translateY(-225px) scale(1);
  transition-delay: 0.25s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(6),
.mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(6),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(6),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(6) {
  -webkit-transform: translateY(-270px) scale(1);
  transform: translateY(-270px) scale(1);
  transition-delay: 0.3s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(7),
.mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(7),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(7),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(7) {
  -webkit-transform: translateY(-315px) scale(1);
  transform: translateY(-315px) scale(1);
  transition-delay: 0.35s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(8),
.mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(8),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(8),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(8) {
  -webkit-transform: translateY(-360px) scale(1);
  transform: translateY(-360px) scale(1);
  transition-delay: 0.4s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(9),
.mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(9),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(9),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(9) {
  -webkit-transform: translateY(-405px) scale(1);
  transform: translateY(-405px) scale(1);
  transition-delay: 0.45s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(10),
.mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(10),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(10),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(10) {
  -webkit-transform: translateY(-450px) scale(1);
  transform: translateY(-450px) scale(1);
  transition-delay: 0.5s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(11),
.mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(11),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(11),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(11) {
  -webkit-transform: translateY(-495px) scale(1);
  transform: translateY(-495px) scale(1);
  transition-delay: 0.55s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(12),
.mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(12),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(12),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(12) {
  -webkit-transform: translateY(-540px) scale(1);
  transform: translateY(-540px) scale(1);
  transition-delay: 0.6s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(13),
.mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(13),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(13),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(13) {
  -webkit-transform: translateY(-585px) scale(1);
  transform: translateY(-585px) scale(1);
  transition-delay: 0.65s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(14),
.mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(14),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(14),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(14) {
  -webkit-transform: translateY(-630px) scale(1);
  transform: translateY(-630px) scale(1);
  transition-delay: 0.7s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(15),
.mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(15),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(15),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(15) {
  -webkit-transform: translateY(-675px) scale(1);
  transform: translateY(-675px) scale(1);
  transition-delay: 0.75s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(16),
.mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(16),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(16),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(16) {
  -webkit-transform: translateY(-720px) scale(1);
  transform: translateY(-720px) scale(1);
  transition-delay: 0.8s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(17),
.mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(17),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(17),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(17) {
  -webkit-transform: translateY(-765px) scale(1);
  transform: translateY(-765px) scale(1);
  transition-delay: 0.85s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(18),
.mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(18),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(18),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(18) {
  -webkit-transform: translateY(-810px) scale(1);
  transform: translateY(-810px) scale(1);
  transition-delay: 0.9s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(19),
.mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(19),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(19),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(19) {
  -webkit-transform: translateY(-855px) scale(1);
  transform: translateY(-855px) scale(1);
  transition-delay: 0.95s;
}

.mfb-component--bl.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(20),
.mfb-component--bl.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(20),
.mfb-component--br.mfb-fountain[data-mfb-toggle="hover"]:hover .mfb-component__list li:nth-child(20),
.mfb-component--br.mfb-fountain[data-mfb-state="open"] .mfb-component__list li:nth-child(20) {
  -webkit-transform: translateY(-900px) scale(1);
  transform: translateY(-900px) scale(1);
  transition-delay: 1s;
}

/*------------------------------------*\
    #LABELS | base
\*------------------------------------*/

/**
 * These are the labels associated to each button,
 * exposed only when hovering the related button.
 * They are called labels but are in fact data-attributes of
 * each button (an anchor tag).
 */

[data-mfb-label]:after {
  content: attr(data-mfb-label);
  opacity: 0;
  transition: all 0.5s;
  background: rgba(0, 0, 0, 0.8);
  padding: 4px 10px;
  border-radius: 3px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  margin-top: -13.5px;
  transition: all 0.5s;
}

[data-mfb-toggle="hover"] [data-mfb-label]:hover:after,
[data-mfb-state="open"] [data-mfb-label]:after {
  content: attr(data-mfb-label);
  opacity: 1;
  transition: all 0.3s;
}

/*------------------------------------*\
    #LABELS | Modifiers
\*------------------------------------*/

.mfb-component--br [data-mfb-label]:after,
.mfb-component--tr [data-mfb-label]:after {
  content: attr(data-mfb-label);
  right: 45px;
}

.mfb-component--br .mfb-component__list [data-mfb-label]:after,
.mfb-component--tr .mfb-component__list [data-mfb-label]:after {
  content: attr(data-mfb-label);
  right: 40px;
}

.mfb-component--tl [data-mfb-label]:after,
.mfb-component--bl [data-mfb-label]:after {
  content: attr(data-mfb-label);
  left: 45px;
}

.mfb-component--tl .mfb-component__list [data-mfb-label]:after,
.mfb-component--bl .mfb-component__list [data-mfb-label]:after {
  content: attr(data-mfb-label);
  left: 40px;
}
